// Import files and libraries
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';


//others
import { IconBack, IconEdit, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData, setUpdateId, setDefaultFormData } from '../../../store/customer/reducer';
import { setDisabledButton, setProDocDefault, setSupportingDocumentPlaceholder, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionCreate, actionUpdate, actionValidateNationalId } from '../../../store/customer/action'


// custom component
import FilePreview from '../../../component/file-preview';
import { currencyFormatValue, formatFileSize, getFileExtension } from '../../../helper/common-helper';
import { ATTACHMENT_TYPE, DEFAULT_USER_ROLE, ENVIRONMENT, MARITAL_STATUS_LIST, PERMISSION_KEY, PERMISSION_PAGE_KEY, RECORD_STATUS, REGEXP, TOAST_TYPE, validatePermission } from '../../../config/constant';
import { getDefaultDateFormatOnly } from '../../../helper/date-helper';
import { showToast } from '../../../helper/toast-service-helper';
import { actionFileUpload } from '../../../store/general/action';
import MultipleFilePreview from '../../../component/multiple-file-upload';
import FormStatus from '../../../component/form-status';
import { actionGetAgentList } from '../../../store/agent/action';

const CustomerFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState({});
    const [editForm, setEditForm] = useState(false);

    const { formData, updateId, formModel } = useSelector((state) => state.customer);
    const { locationList } = useSelector((state) => state.location);
    const { organizationList } = useSelector((state) => state.organization);
    const { agentList } = useSelector((state) => state.agent);
    const { disabledButton, profile, supportingDocuments } = useSelector((state) => state.general);
    const { defaultUserPermission, userData } = useSelector((state) => state.auth);

    useEffect(() => {
        setErrors({});
        setEditForm(updateId ? false : true);
        setDefaultOrg();

    }, [formModel]);

    // useEffect(() => {
    //     setErrors({});
    //     // clear the form details
    //     dispatch(setDefaultFormData());
    //     dispatch(setProDocDefault());
    //     // remove the update id
    //     dispatch(setUpdateId(null));
    //     // show the model
    //     dispatch(setFormModel(false));

    // }, []);

    const setDefaultOrg = () => {
        if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION) {
            let data = {
                ...formData,
                organization_id: userData.id,
            };

            dispatch(setFormData(data));
            dispatch(actionGetAgentList(userData.id));
        } else if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT) {
            let data = {
                ...formData,
                organization_id: userData.organization_id,
                agent_id: userData.id
            };

            dispatch(setFormData(data));
            dispatch(actionGetAgentList(userData.organization_id));
        }
    }

    // form validation schema
    let schema = Yup.object().shape({
        first_name: Yup.string()
            .max(50, 'First name must be at most 50 characters long')
            .required('First name is required'),
        last_name: Yup.string()
            .max(50, 'Last name must be at most 50 characters long')
            .required('Last name is required'),
        middle_name: Yup.string()
            .max(50, 'Middle name must be at most 50 characters long')
            .required('Middle name is required'),
        email: Yup.string()
            .max(255, 'Email must be at most 255 characters long')
            .matches(REGEXP.emailRegExp, 'Invalid email address')
            .required('Email is required'),
        phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(20, 'Phone number must be at most 20 characters long')
            .required('Phone number is required'),
        national_id:
            Yup.string()
                .matches(REGEXP.numberRegExp, 'National ID is not valid')
                .max(20, 'National ID must be at most 20 characters long')
                .required('National ID is required'),
        marital_status:
            Yup.string()
                .required('Marital Status is required'),
        occupation:
            Yup.string()
                .max(255, 'Occupation must be at most 255 characters long')
                .required('Occupation is required'),
        nominee_name:
            Yup.string()
                .max(255, 'Next of Kin Name must be at most 255 characters long')
                .required('Next of Kin Name is required'),
        nominee_relationship:
            Yup.string()
                .max(255, 'Next of Kin Relationship must be at most 255 characters long')
                .required('Next of Kin Relationship is required'),
        nominee_phone_number:
            Yup.string()
                .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
                .max(20, 'Phone number must be at most 20 characters long')
                .required('Next of Kin Phone number is required'),
        address:
            Yup.string()
                .max(255, 'Address must be at most 255 characters long')
                .required('Address is required'),
        remarks:
            Yup.string()
                .max(255, 'Remarks must be at most 255 characters long')
                .required('Remarks is required'),
        location_id:
            Yup.string()
                .required('Location is required'),
        organization_id:
            Yup.string()
                .required('Organization is required'),
        agent_id:
            Yup.string()
                .required('Agent is required')

    });

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            let supporting_documents = [];
            if (Object.values(supportingDocuments).length > 0) {
                Object.values(supportingDocuments).map(doc => {
                    if (doc?.isNew) {
                        supporting_documents.push(doc.id);
                    }
                });
            }

            const finalData = {
                first_name: formData.first_name,
                middle_name: formData.middle_name,
                last_name: formData.last_name,
                national_id: formData.national_id + Math.floor(10000 + Math.random() * 90000),
                email: formData.email,
                phone_number: formData.phone_number,
                citizen_ship: formData.citizen_ship,
                cladfy_request_id: formData.cladfy_request_id,
                serial_number: formData.serial_number,
                dob: formData.dob,
                gender: formData.gender,
                marital_status: formData.marital_status,
                occupation: formData.occupation,
                nominee_name: formData.nominee_name,
                nominee_relationship: formData.nominee_relationship,
                nominee_phone_number: formData.nominee_phone_number,
                address: formData.address,
                remarks: formData.remarks,
                location_id: formData.location_id,
                profile: profile !== null ? profile.file.id : null,
                active: formData.active,
                organization_id: formData.organization_id,
                agent_id: formData.agent_id,
                supporting_documents
            };

            if (updateId) { //check the form action update or create
                // update data with update id
                dispatch(actionUpdate(finalData, updateId));
            } else {
                // create form data
                dispatch(actionCreate(finalData));
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        if (name === 'dob') {
            value = getDefaultDateFormatOnly(value);
        }
        if (name === 'organization_id') {
            dispatch(actionGetAgentList(value));
        }
        let data = {
            ...formData,
            [name]: value,
        };

        dispatch(setFormData(data));
        validateField(name, value);
    };

    const validateNationalId = (e) => {

        if (e.trim() != "") {
            dispatch(actionValidateNationalId(formData.national_id));
        } else {
            showToast(TOAST_TYPE.ERROR, "Provide the National ID to validate", 'top-right');
        }

    };

    const uploadFile = (e) => {
        const { name } = e.target;
        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        if (name === 'profile') {
            const formData = new FormData();
            formData.append('file', checkFile);
            dispatch(actionFileUpload(formData));
        } else {
            Array.from(e.target.files).forEach((file) => {
                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                let ext = getFileExtension(file.name);
                dispatch(setSupportingDocumentPlaceholder({
                    inx, file: {
                        name: file.name,
                        type: file.type,
                        ext,
                        size: formatFileSize(file.size),
                        isNew: true
                    }
                }));
                const formSDData = new FormData();
                formSDData.append('file', file); // Append the file to the FormData object

                dispatch(actionFileUpload(formSDData, inx, name));
            });
        }
    }
    return (
        <>

            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-10 mb-3">
                        <div className='d-flex align-items-center'>
                            {
                                defaultUserPermission &&
                                defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] &&
                                !defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][PERMISSION_KEY.ADD_ONLY] && (
                                    <IconBack
                                        width={32}
                                        height={32}
                                        onIconClick={closeModal}
                                        customClass='cursor-pointer me-2 '
                                    />
                                )}
                            <h3 className='flex-shrink-1 mb-0'>{updateId ? 'Update Customer' : 'Create New Customer'}</h3>
                        </div>
                    </div>
                    {updateId && defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.CUSTOMER, PERMISSION_KEY.UPDATE) && (
                        <div className="col-md-2 mb-3 text-end">
                            <button
                                className="btn btn-purple-primary me-3"
                                type='button'
                                onClick={e => setEditForm(true)}
                            >
                                <IconEdit customClass='text-white' /> Edit
                            </button>
                        </div>
                    )}
                </div>
                {updateId && (
                    <div className='row mb-3'>
                        <div className='col-3'>
                            <div className='card shadow  rounded'>
                                <div className='card-body'>
                                    <h5>Wallet Amount</h5>
                                    <div className='form-group text-end'>
                                        {formData?.wallet_payment && currencyFormatValue(formData.wallet_payment)} KSH
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='row'>
                    <div className="col-md-12">
                        <div className='row'>
                            <div className='col-md-1'>
                                {profile ? (
                                    <div className='placeholder-image-bg'>
                                        <img src={`data:${profile?.type};base64,${profile?.base64}`} />
                                    </div>
                                ) : (
                                    <div className='placeholder-image-bg'>
                                        <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                    </div>
                                )}
                            </div>
                            {editForm && (
                                <div className='col-md-2 align-self-center'>
                                    <div className="upload-btn-wrapper">
                                        <button className="upload-btn btn">Upload a file</button>
                                        <input type="file" name="profile" onChange={uploadFile} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen className='custom-accordiant'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Basic information</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>National Identity Number</label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control "
                                                        name="national_id"
                                                        disabled={!editForm}
                                                        value={formData.national_id}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input-group-text cursor-pointer"
                                                        onClick={e => validateNationalId(formData.national_id)}
                                                    >
                                                        Validate
                                                    </span>
                                                </div>
                                                {errors.national_id && <span className="error-message">{errors.national_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Serial Number</label>
                                                <input
                                                    readOnly={true}
                                                    type="text"
                                                    className="form-control "
                                                    name="serial_number"
                                                    disabled={!editForm}
                                                    value={formData.serial_number}
                                                    onChange={handleChange}
                                                />
                                                {/* {errors.serial_number && <span className="error-message">{errors.serial_number}</span>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>First Name</label>
                                                <input type="text" className="form-control "
                                                    readOnly={true}
                                                    name="first_name"
                                                    disabled={!editForm}
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                />
                                                {/* {errors.first_name && <span className="error-message">{errors.first_name}</span>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Middle Name</label>
                                                <input type="text" className="form-control "
                                                    readOnly={true}
                                                    name="middle_name"
                                                    disabled={!editForm}
                                                    value={formData.middle_name}
                                                    onChange={handleChange}
                                                />
                                                {/* {errors.middle_name && <span className="error-message">{errors.middle_name}</span>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control "
                                                    readOnly={true}
                                                    name="last_name"
                                                    disabled={!editForm}
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                />
                                                {/* {errors.last_name && <span className="error-message">{errors.last_name}</span>} */}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="text" className="form-control "
                                                    name="email"
                                                    disabled={!editForm}
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <span className="error-message">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control "
                                                    name="phone_number"
                                                    disabled={!editForm}
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                />
                                                {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Gender</label>
                                                <input type="text" className="form-control "
                                                    readOnly={true}
                                                    name="gender"
                                                    disabled={!editForm}
                                                    value={formData.gender}
                                                    onChange={handleChange}
                                                />
                                                {/* {errors.gender && <span className="error-message">{errors.gender}</span>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Citizenship</label>
                                                <input type="text" className="form-control "
                                                    readOnly={true}
                                                    name="citizen_ship"
                                                    disabled={!editForm}
                                                    value={formData.citizen_ship}
                                                    onChange={handleChange}
                                                />
                                                {/* {errors.citizen_ship && <span className="error-message">{errors.citizen_ship}</span>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Date of Birth</label>
                                                <input type="text" className="form-control "
                                                    readOnly={true}
                                                    name="dob"
                                                    disabled={!editForm}
                                                    value={formData.dob}
                                                    onChange={handleChange}
                                                />
                                                {/* {errors.dob && <span className="error-message">{errors.dob}</span>} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Marital Status</label>
                                                <Dropdown
                                                    disabled={!editForm}
                                                    value={formData.marital_status}
                                                    onChange={handleChange}
                                                    options={MARITAL_STATUS_LIST}
                                                    optionLabel="name"
                                                    optionValue="id"
                                                    placeholder="Select a status"
                                                    className="w-100 custom-single-dropdown"
                                                    name='marital_status'
                                                />

                                                {errors.marital_status && <span className="error-message">{errors.marital_status}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Address</label>
                                                <input type="text" className="form-control "
                                                    name="address"
                                                    disabled={!editForm}
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                />
                                                {errors.address && <span className="error-message">{errors.address}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Location</label>

                                                <Dropdown
                                                    disabled={!editForm}
                                                    value={formData.location_id}
                                                    onChange={handleChange}
                                                    options={locationList}
                                                    optionLabel="location_name"
                                                    optionValue="id"
                                                    placeholder="Select a location"
                                                    className="w-100 custom-single-dropdown"
                                                    name='location_id'
                                                />

                                                {errors.location_id && <span className="error-message">{errors.location_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Next of Kin Name</label>
                                                <input type="text" className="form-control "
                                                    name="nominee_name"
                                                    disabled={!editForm}
                                                    value={formData.nominee_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.nominee_name && <span className="error-message">{errors.nominee_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Next of Kin Relationship</label>
                                                <input type="text" className="form-control "
                                                    name="nominee_relationship"
                                                    disabled={!editForm}
                                                    value={formData.nominee_relationship}
                                                    onChange={handleChange}
                                                />
                                                {errors.nominee_relationship && <span className="error-message">{errors.nominee_relationship}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Next of Kin Phone Number</label>
                                                <input type="text" className="form-control "
                                                    name="nominee_phone_number"
                                                    disabled={!editForm}
                                                    value={formData.nominee_phone_number}
                                                    onChange={handleChange}
                                                />
                                                {errors.nominee_phone_number && <span className="error-message">{errors.nominee_phone_number}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Remarks</label>
                                                <input type="text" className="form-control "
                                                    name="remarks"
                                                    disabled={!editForm}
                                                    value={formData.remarks}
                                                    onChange={handleChange}
                                                />
                                                {errors.remarks && <span className="error-message">{errors.remarks}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Occupation</label>
                                                <input type="text" className="form-control "
                                                    name="occupation"
                                                    disabled={!editForm}
                                                    value={formData.occupation}
                                                    onChange={handleChange}
                                                />
                                                {errors.occupation && <span className="error-message">{errors.occupation}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label className='w-100'>Documents</label>
                                                {editForm && (
                                                    <div className="upload-btn-wrapper ">
                                                        <button className="upload-btn-bdr btn ">Upload a file</button>
                                                        <input type="file" name={ATTACHMENT_TYPE.CUSTOMER_SUPPORT_DOCUMENT} multiple onChange={uploadFile} />
                                                    </div>
                                                )}
                                                {/* {errors.name && <span className="error-message">{errors.name}</span>} */}
                                            </div>
                                        </div>
                                        <MultipleFilePreview files={supportingDocuments} />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Agent Information</Accordion.Header>
                                <Accordion.Body>
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Organization</label>
                                                {
                                                    parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION || parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT ? (
                                                        <Dropdown
                                                            disabled={true}
                                                            value={formData.organization_id}
                                                            // onChange={handleChange}
                                                            options={organizationList}
                                                            optionLabel="organization_name"
                                                            optionValue="id"
                                                            placeholder="Select a organization"
                                                            className="w-100 custom-single-dropdown"
                                                            name='organization_id'
                                                        />
                                                    ) : (
                                                        <Dropdown
                                                            disabled={!editForm}
                                                            value={formData.organization_id}
                                                            onChange={handleChange}
                                                            options={organizationList}
                                                            optionLabel="organization_name"
                                                            optionValue="id"
                                                            placeholder="Select a organization"
                                                            className="w-100 custom-single-dropdown"
                                                            name='organization_id'
                                                        />
                                                    )
                                                }


                                                {errors.organization_id && <span className="error-message">{errors.organization_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Agent</label>
                                                {
                                                    parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT ? (
                                                        <Dropdown
                                                            disabled={true}
                                                            value={formData.agent_id}
                                                            options={agentList}
                                                            optionLabel="full_name"
                                                            optionValue="id"
                                                            placeholder="Select a agent"
                                                            className="w-100 custom-single-dropdown"
                                                            name='agent_id'
                                                        />
                                                    ) : (
                                                        <Dropdown
                                                            disabled={!editForm}
                                                            value={formData.agent_id}
                                                            onChange={handleChange}
                                                            options={agentList}
                                                            optionLabel="full_name"
                                                            optionValue="id"
                                                            placeholder="Select a agent"
                                                            className="w-100 custom-single-dropdown"
                                                            name='agent_id'
                                                        />
                                                    )
                                                }


                                                {errors.organization_id && <span className="error-message">{errors.organization_id}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>
                    <div className='col-12 mb-3'>
                        {
                            defaultUserPermission &&
                            defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] &&
                            !defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][PERMISSION_KEY.ADD_ONLY] && (
                                <FormStatus
                                    onStatusClick={handleChange}
                                    active={formData.active}
                                    id={updateId}
                                    editForm={editForm}
                                />
                            )}
                    </div>
                    <Divider />
                    <div className="col-12 text-end">
                        <div className="form-group mb-3">
                            {editForm && (
                                <>
                                    {
                                        defaultUserPermission &&
                                        defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] &&
                                        !defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][PERMISSION_KEY.ADD_ONLY] && (
                                            <button
                                                className="btn  btn-purple-primary-outline me-3"
                                                type='button'
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    <button
                                        disabled={disabledButton}
                                        className="btn  btn-purple-primary"
                                        type='submit'
                                    >
                                        {disabledButton ? <Spinner /> : null} {updateId ? 'Update' : 'Create'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            <FilePreview />
        </>
    );
};


export default CustomerFormModel;
