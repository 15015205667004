import api from './api';

import { axiosOptions } from '../helper/common-helper';

export default {

    async login(data) {
        const axiosConfig = await axiosOptions();
        return await api().post('service-one/auth/login', data, axiosConfig);
    },
    async forgotPassword(data) {
        let axiosConfig = await axiosOptions();
        return await api().post('service-one/auth/forgot-password', data, axiosConfig);
    },
    async resetPassword(data) {
        const axiosConfig = await axiosOptions();
        axiosConfig.headers["Tokentype"] = 'forgot-password';
        axiosConfig.headers["Authorization"] =`Bearer ${data.token}`;
        
        return await api().post(`service-one/auth/reset-password`, data, axiosConfig);
    }
}