//reducer
import { setUpdateId, setFormData, setFormModel, setAgentList, setLocationList } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import locationService from '../../services/location'
import agentService from '../../services/agent'


// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {

        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.LOCATION,PERMISSION_KEY.CREATE)){
        //     return;
        // }

        const response = await locationService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.CREATE) {
            await dispatch(actionGetAll());
            await dispatch(setFormModel(false));
            showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');
        }
    }
}

// export user update action
export const actionUpdate = (data, id) => {
    return async (dispatch, getState) => {

        
        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.LOCATION,PERMISSION_KEY.UPDATE)){
        //     return;
        // }

        const response = await locationService.update(data, id);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.UPDATE) {
            await dispatch(actionGetAll());
            await dispatch(setFormModel(false));
            showToast(TOAST_TYPE.SUCCESS, 'Successfully updated', 'top-right');
        }
    }
}

// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {

        
        const {defaultUserPermission} = getState().auth;
        if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.LOCATION,PERMISSION_KEY.READ)){
            return;
        }


        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset,
            limit: getParams.limit,
        };
        const response = await locationService.getAll(data);
        // dispatch(updateLoaderStatus(false));
        dispatch(setTableLoader(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            await dispatch(setTableData({
                data: response.data.data.locations,
                totalRecord: response.data.data.total_count,
                tableKey: TABLES.USER
            }));
        }
    }
}

// export user get by id action
export const actionGetById = (id) => {
    return async (dispatch, getState) => {

        
        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.LOCATION,PERMISSION_KEY.READ)){
        //     return;
        // }

        const response = await locationService.getById(id);
        dispatch(updateLoaderStatus(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            const data = response.data.data.location;
            await dispatch(setUpdateId(id));
            await dispatch(setFormData({
                location_name: data.location_name,
                address: data.address,
                village: data.village,
                town: data.town,
                sub_country: data.sub_country,
                country: data.country,
                state: data.state,
                agent_id: data.agent_id ? data.agent_id: null,
                active: data.active
            }));

            await dispatch(setFormModel(true));

        }
    }
}



export const actionGetLocationList = () => {
    return async (dispatch, getState) => {
      
        const response = await locationService.list();
        await dispatch(updateLoaderStatus(false));
        if (response?.status === 200) {
            await dispatch(setLocationList(response.data.data.locations));
        } 
    }
}