// Import files and libraries
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';


//others
import { IconBack, IconEdit, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData } from '../../../store/organization/reducer';
import { setDisabledButton, setSupportingDocumentPlaceholder, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionProfileUpload, actionSDUpload, actionCreate, actionUpdate, actionFileById } from '../../../store/organization/action'


// custom component
import FilePreview from '../../../component/file-preview';
import { formatFileSize, getFileExtension, truncateFileName } from '../../../helper/common-helper';
import { ATTACHMENT_TYPE, DEFAULT_USER_ROLE, ENVIRONMENT, PERMISSION_KEY, PERMISSION_LIST, PERMISSION_PAGE_KEY, RECORD_STATUS, REGEXP, ROLE_LIST, TOAST_TYPE, validatePermission } from '../../../config/constant';
import { getDefaultDateFormatOnly } from '../../../helper/date-helper';
import { showToast } from '../../../helper/toast-service-helper';
import { setFormDefaultPermission } from '../../../store/auth/reducer';
import { actionFileUpload } from '../../../store/general/action';
import MultipleFilePreview from '../../../component/multiple-file-upload';
import PermissionList from '../../../component/permission-list';
import FormStatus from '../../../component/form-status';
import { actionGetLocationList } from '../../../store/location/action';

const OrganizationFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState({});
    const [editForm, setEditForm] = useState(false);

    const { formData, updateId, formModel } = useSelector((state) => state.organization);
    const { locationList } = useSelector((state) => state.location);
    const { disabledButton, profile, supportingDocuments } = useSelector((state) => state.general);
    const { formDefaultPermission, defaultUserPermission, userData } = useSelector((state) => state.auth);

    useEffect(() => {
        setErrors({});
        setEditForm(updateId ? false : true);
        dispatch(actionGetLocationList());
    }, [formModel]);


    // form validation schema
    let schema = Yup.object().shape({
        name: Yup.string()
            .max(255, 'Name must be at most 255 characters long')
            .required('Name is required'),
        registration_number: Yup.string()
            .required('Registration Number is required'),
        email: Yup.string()
            .max(255, 'Email must be at most 255 characters long')
            .matches(REGEXP.emailRegExp, 'Invalid email address')
            .required('Email is required'),
        phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(20, 'Phone number must be at most 20 characters long')
            .required('Phone number is required'),
        locations: Yup.array()
            .of(Yup.string().required('Location is required'))
            .min(1, 'At least one Location is required')
            .required('Location are required'),
        address: Yup.string()
            .max(255, 'Address must be at most 255 characters long')
            .required('Address is required'),

        gem_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Gem Target is not valid')
            .max(20, 'Monthly Gem Target must be at most 20 characters long')
            .required('Monthly Gem Target is required'),
        metal_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Metal Target is not valid')
            .max(20, 'Monthly Metal Target must be at most 20 characters long')
            .required('Monthly Metal Target is required'),
        customer_name: Yup.string()
            .max(255, 'Customer Name must be at most 255 characters long')
            .required('Customer Name is required'),
        customer_email: Yup.string()
            .max(255, 'Email must be at most 255 characters long')
            .email('Invalid email address')
            .required('Email is required'),
        customer_phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(20, 'Phone number must be at most 20 characters long')
            .required('Phone number is required'),
        customer_designation: Yup.string()
            .max(255, 'Designation must be at most 255 characters long')
            .required('Designation is required')

    });

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            let supporting_documents = [];
            if (Object.values(supportingDocuments).length > 0) {
                Object.values(supportingDocuments).map(doc => {
                    if (doc?.isNew) {
                        supporting_documents.push(doc.id);
                    }
                });
            }

            const finalData = {
                name: formData.name,
                registration_number: formData.registration_number,
                date_of_formation: formData.date_of_formation,
                email: formData.email,
                phone_number: formData.phone_number,
                locations: formData.locations,
                address: formData.address,
                gem_target: formData.gem_target,
                metal_target: formData.metal_target,
                profile: profile !== null ? profile.file.id : null,
                active: formData.active,
                locations: formData.locations,
                supporting_documents,
                permissions: Object.values(formDefaultPermission).filter(permission => permission !== 0),
                customer_name: formData.customer_name,
                customer_email: formData.customer_email,
                customer_phone_number: formData.customer_phone_number,
                customer_designation: formData.customer_designation
            };

            if (updateId) { //check the form action update or create
                // update data with update id
                dispatch(actionUpdate(finalData, updateId));
            } else {
                // create form data
                dispatch(actionCreate(finalData));
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        let data;

        if (name === 'date_of_formation') {
            value = getDefaultDateFormatOnly(value);
        }

        data = {
            ...formData,
            [name]: value,
        };
        dispatch(setFormData(data));
        validateField(name, value);
    };

    const uploadFile = (e) => {
        const { name } = e.target;
        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        if (name === 'profile') {
            const formData = new FormData();
            formData.append('file', checkFile);
            dispatch(actionFileUpload(formData));
        } else {
            Array.from(e.target.files).forEach((file) => {
                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                let ext = getFileExtension(file.name);
                dispatch(setSupportingDocumentPlaceholder({
                    inx, file: {
                        name: file.name,
                        type: file.type,
                        ext,
                        size: formatFileSize(file.size),
                        isNew: true
                    }
                }));
                const formSDData = new FormData();
                formSDData.append('file', file); // Append the file to the FormData object

                dispatch(actionFileUpload(formSDData, inx, name));
            });
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-10 mb-3">
                        <div className='d-flex align-items-center'>
                            <IconBack
                                width={32}
                                height={32}
                                onIconClick={closeModal}
                                customClass='cursor-pointer'
                            />
                            <h3 className='flex-shrink-1 ms-2 mb-0'>{updateId ? 'Update Organisation' : 'Create New Organisation'}</h3>
                        </div>
                    </div>
                    {updateId && defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.ORGANIZATION, PERMISSION_KEY.UPDATE) && (
                        <div className="col-md-2 mb-3 text-end">
                            <button
                                className="btn btn-purple-primary me-3"
                                type='button'
                                onClick={e => setEditForm(true)}
                            >
                                <IconEdit customClass='text-white' /> Edit
                            </button>
                        </div>
                    )}
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <div className='row'>
                            <div className='col-md-1'>
                                {profile ? (
                                    <div className='placeholder-image-bg'>
                                        <img src={`data:${profile?.type};base64,${profile?.base64}`} />
                                    </div>
                                ) : (
                                    <div className='placeholder-image-bg'>
                                        <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                    </div>
                                )}
                            </div>
                            {editForm && (
                                <div className='col-md-2 align-self-center'>
                                    <div className="upload-btn-wrapper">
                                        <button className="upload-btn btn">Upload a file</button>
                                        <input type="file" name="profile" onChange={uploadFile} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen className='custom-accordiant'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Organisation information</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Name</label>
                                                <input type="text" className="form-control "
                                                    name="name"
                                                    disabled={!editForm}
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && <span className="error-message">{errors.name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Registration Number</label>
                                                <input type="text" className="form-control "
                                                    name="registration_number"
                                                    disabled={!editForm}
                                                    value={formData.registration_number}
                                                    onChange={handleChange}
                                                />
                                                {errors.registration_number && <span className="error-message">{errors.registration_number}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Date of Formation</label>
                                                <Calendar
                                                    disabled={!editForm}
                                                    value={formData.date_of_formation ? new Date(formData.date_of_formation) : ''}
                                                    onChange={handleChange}
                                                    dateFormat="dd-mm-yy"
                                                    name="date_of_formation"
                                                    className='date-picker-input'
                                                    showIcon
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="text" className="form-control "
                                                    name="email"
                                                    disabled={!editForm}
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <span className="error-message">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control "
                                                    name="phone_number"
                                                    disabled={!editForm}
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                />
                                                {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                                            </div>
                                        </div>


                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Gem Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="gem_target"
                                                        disabled={!editForm}
                                                        value={formData.gem_target}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input-group-text" id="gem_targe">Carat</span>
                                                </div>
                                                {errors.gem_target && <span className="error-message">{errors.gem_target}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Metal Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="metal_target"
                                                        disabled={!editForm}
                                                        value={formData.metal_target}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input-group-text" id="metal_targe">GM</span>
                                                </div>
                                                {errors.metal_target && <span className="error-message">{errors.metal_target}</span>}
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Address</label>
                                                <textarea className="form-control "
                                                    name="address"
                                                    disabled={!editForm}
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    rows={3}
                                                >
                                                    {formData.address}
                                                </textarea>
                                                {errors.address && <span className="error-message">{errors.address}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Mining Location</label>
                                                <MultiSelect
                                                    disabled={!editForm}
                                                    value={formData.locations}
                                                    name='locations'
                                                    onChange={handleChange}
                                                    options={locationList}
                                                    optionLabel="location_name"
                                                    optionValue="id"
                                                    display="chip"
                                                    placeholder="Select locations"
                                                    className="custom-multiselect w-100"
                                                />
                                                {errors.locations && <span className="error-message">{errors.locations}</span>}
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label className='w-100'>Supporting documents</label>
                                                {editForm && (
                                                    <div className="upload-btn-wrapper ">
                                                        <button className="upload-btn-bdr btn ">Upload a file</button>
                                                        <input type="file" name={ATTACHMENT_TYPE.ORG_SUPPORT_DOCUMENT} multiple onChange={uploadFile} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <MultipleFilePreview files={supportingDocuments} />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Contact person information</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Name</label>
                                                <input type="text" className="form-control "
                                                    name="customer_name"
                                                    disabled={!editForm}
                                                    value={formData.customer_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.customer_name && <span className="error-message">{errors.customer_name}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Email</label>
                                                <input type="text" className="form-control "
                                                    name="customer_email"
                                                    disabled={!editForm}
                                                    value={formData.customer_email}
                                                    onChange={handleChange}
                                                />
                                                {errors.customer_email && <span className="error-message">{errors.customer_email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Phone</label>
                                                <input type="text" className="form-control "
                                                    name="customer_phone_number"
                                                    disabled={!editForm}
                                                    value={formData.customer_phone_number}
                                                    onChange={handleChange}
                                                />
                                                {errors.customer_phone_number && <span className="error-message">{errors.customer_phone_number}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Designation</label>
                                                <input type="text" className="form-control "
                                                    name="customer_designation"
                                                    disabled={!editForm}
                                                    value={formData.customer_designation}
                                                    onChange={handleChange}
                                                />
                                                {errors.customer_designation && <span className="error-message">{errors.customer_designation}</span>}
                                            </div>
                                        </div>

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Permission</Accordion.Header>
                                <Accordion.Body>
                                    {parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.SUPER_ADMIN ? (
                                        <PermissionList editForm={editForm} />
                                    ) : (
                                        <PermissionList editForm={false} />
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='col-12 mb-3'>
                        <FormStatus
                            onStatusClick={handleChange}
                            active={formData.active}
                            id={updateId}
                            editForm={editForm}
                        />
                    </div>
                    <Divider />
                    <div className="col-12 text-end">
                        <div className="form-group mb-3">
                            {editForm && (
                                <>
                                    <button
                                        className="btn  btn-purple-primary-outline me-3"
                                        type='button'
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={disabledButton}
                                        className="btn  btn-purple-primary"
                                        type='submit'
                                    >
                                        {disabledButton ? <Spinner /> : null} {updateId ? 'Update' : 'Create'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            <FilePreview />
        </>
    );
};


export default OrganizationFormModel;
