
// Import files and libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


// others
import { IconPlus } from '../../../svg-icons';
import {  PERMISSION_KEY, PERMISSION_PAGE_KEY, PRODUCT_CERTIFICATION,  RECORD_STATUS, TABLES, validatePermission } from '../../../config/constant';
import ProductFormModel from './form-modal';


//reducer
import { setUpdateId, setFormModel, setDefaultFormData } from '../../../store/product/reducer';
import { setTableLoader, updateLoaderStatus, updatePageTitle } from '../../../store/general/reducer';

//action
import { actionGetAll, actionGetById } from '../../../store/product/action';

//custom component
import DataTableComponent from '../../../component/data-table';
import { getDisplayIdByType } from '../../../helper/common-helper';
import PermissionDenied from '../../../component/permission-denied';
import { actionGetCategoryList } from '../../../store/category/action';



const ProductPage = () => {

  // dispatch action
  const dispatch = useDispatch();

  // get the table details like sort, order, page, search, page, page size, table data, total records
  const { search, formModel } = useSelector((state) => state.product);
  const { defaultUserPermission } = useSelector((state) => state.auth);
  const { sort, order, limit, offset, totalRecords } = useSelector((state) => state.table[TABLES.USER]);

  // handle model show
  const showModel = async () => {
    // clear the form details
    dispatch(setDefaultFormData());
    // remove the update id
    dispatch(setUpdateId(null));

    // show the model
    dispatch(setFormModel(true));
  };

  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    // call the api function
    dispatch(setTableLoader(true));
    getAllData();
  }, [limit, offset, search, sort, order, defaultUserPermission]);
  
  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    dispatch(updatePageTitle('Proudct'));
    // remove the update id
    dispatch(setUpdateId(null));
    // show the model
    dispatch(setFormModel(false));
    getAllCategory();
  }, []);


  // get data form the api
  const getAllData = async () => {
    // dispatch the data to the API call
    dispatch(actionGetAll())
  }

  // get data form the api
  const getAllCategory = async () => {
    // dispatch the data to the API call
    dispatch(actionGetCategoryList())
  }


  //get the specific details by id
  const getById = async (id) => {
    // dispatch the action
    dispatch(updateLoaderStatus(true));
    dispatch(actionGetById(id));
  }

  const tableColumns = [

    {
      field: 'row_ref_number',
      header: 'ID'
    },
    {

      field: 'sub_category_name',
      header: 'PRODUCT'
    },
    {

      field: 'loyalty_percentage',
      header: 'LOYALTY %',
      headerAlign: 'text-end',
      bodyAlign: 'text-end',
      body: (row) => {
        return `${row.loyalty_percentage}%`
      }
    },
    {

      field: 'certification_need',
      header: 'CERTIFICATION',
      headerAlign: 'text-center',
      bodyAlign: 'text-center',
      body: (row) => {
        return parseInt(row.certification_need) === PRODUCT_CERTIFICATION.YES.id ? PRODUCT_CERTIFICATION.YES.name : PRODUCT_CERTIFICATION.NO.name;
      }
    },
    {
      field: 'certification_charges',
      header: 'CERTIFICATION CHARGES',
      headerAlign: 'text-end',
      bodyAlign: 'text-end'
    },
    {
      sortable: false,
      field: 'status',
      header: 'STATUS',
      headerAlign: 'text-center',
      bodyAlign: 'text-center',
      body: (row) => {
        return (<>
          {row.active === RECORD_STATUS.ACTIVE.id ? (
            <span className='badge badge-active w-100'>{RECORD_STATUS.ACTIVE.name}</span>
          ) : (
            <span className='badge badge-inactive w-100'>{RECORD_STATUS.INACTIVE.name}</span>
          )}
        </>)
      }
    }
  ];


  const selectedRow = (e) => {
    getById(e.data.id);
  }
  // page design
  return (
    <>
      {defaultUserPermission && validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.PRODUCT,PERMISSION_KEY.READ)? (
        <div className='container-fluid'>
          <div className="card mb-3">
            <div className="card-body">
              {formModel ? (
                <div className="row">
                  <div className='col-12'>
                    <ProductFormModel />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className='col-6'>
                    <h3 className='page-title'>Products</h3>
                    <p className='sub-page-title table-total-count'>Total number of Products - {totalRecords}</p>
                  </div>
                  {defaultUserPermission && validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.PRODUCT,PERMISSION_KEY.ADD) && (
                    <div className='col-6 text-end'>
                      <button className='btn btn-orange-primary' onClick={showModel}>
                        <IconPlus customClass='text-white' /> Create Product
                      </button>
                    </div>
                  )}
                  <div className='col-12'>
                    <DataTableComponent
                      tableKey={TABLES.USER}
                      columns={tableColumns}
                      onTableRowSelect={selectedRow}
                    />
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      ) : defaultUserPermission && <PermissionDenied />}
    </>
  );

}

export default ProductPage;
