import { createSlice } from '@reduxjs/toolkit'
import { SIDEBAR_OPTION } from '../../config/constant';

let url = window.location.href,
  retObject = {},
  parameters;

if (url.indexOf('?') !== -1) {
  url = url.split('?')[1];

  parameters = url.split('&');

  for (var i = 0; i < parameters.length; i++) {
    retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1];
  }

}

const preSideBarActive = localStorage.getItem('sidebar');
let sideBarActive = preSideBarActive ? preSideBarActive : SIDEBAR_OPTION.ICON_SIDEBAR;

const initialState = {
  sideBarActive,
  createUserModal: false,
  networkOffline: !window.navigator.onLine,
  showLogin: true,
  pageTitle: 'Madini Chapaa',
  loader: false,
  showPassword: false,
  urlParams: retObject,
  loginSection: true,
  toastStatus: false,
  toastData: {
    severity: null,
    summary: null,
    detail: null
  },
  deviceWidth: 0,
  tableLoader: false,
  disabledButton: false,
  filePreview: null,
  filePreviewModel: false,
  profile: null,
  customer_photo: null,
  metal_photo: null,
  weight_photo: null,
  purity_photo: null,
  supportingDocuments: {}
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setSideBarAction(state, action) {
      state.sideBarActive = action.payload;
      action.payload
        ? localStorage.setItem('sidebar', action.payload)
        : localStorage.removeItem('sidebar');
    },
    setNetworkOffline(state, action) {
      state.networkOffline = action.payload;
    },
    updatePageTitle(state, action) {
      state.pageTitle = `${action.payload} || Madini Chapaa`;
    },
    updateLoaderStatus(state, action) {
      state.loader = action.payload;
    },
    updateURLParams(state, action) {
      let url = window.location.href,
        retObject = {},
        parameters;

      if (url.indexOf('?') !== -1) {
        url = url.split('?')[1];

        parameters = url.split('&');

        for (var i = 0; i < parameters.length; i++) {
          retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1];
        }

      }
      state.urlParams = retObject;
    },

    setLoginSection(state, action) {
      state.loginSection = action.payload;
    },
    setToastStatus(state, action) {
      state.toastStatus = action.payload;
    },
    toastData(state, action) {
      state.toastStatus = action.payload;
    },
    setDeviceWidth(state, action) {
      state.deviceWidth = action.payload;
    },
    setTableLoader(state, action) {
      state.tableLoader = action.payload;
    },
    setDisabledButton(state, action) {
      state.disabledButton = action.payload;
    },
    setFilePreviewModel(state, action) {
      state.filePreviewModel = action.payload;
    },
    setFilePreview(state, action) {
      state.filePreview = action.payload;
    },
    setFileData: (state, action) => {
      const {name,value}=action.payload;
      state[name] = value;
    },
    setBulkSupportingDocumentData: (state, action) => {
      // Replace the placeholder with the uploaded file data
      state.supportingDocuments = action.payload;
    },
    setSupportingDocumentData: (state, action) => {
      // Replace the placeholder with the uploaded file data
      state.supportingDocuments[action.payload.inx] = {
        ...action.payload.data
      };
    },
    setSupportingDocumentPlaceholder: (state, action) => {
      // Create a placeholder for the file before upload
      state.supportingDocuments[action.payload.inx] = { placeholder: true, uploadProgress: 0, file: action.payload.file };
    },
    setSupportingDocumentProgress: (state, action) => {

      state.supportingDocuments[action.payload.inx] = {
        ...state.supportingDocuments[action.payload.inx],
        uploadProgress: action.payload.percentCompleted
      };
    },
    setProDocDefault: (state, action) => {
      state.supportingDocuments = {};
      state.profile = null;
    },
    setClearGeneralState:(state,action)=>{
      state.supportingDocuments = {};
      state.profile = null;
      state.tableLoader= false;
      state.disabledButton= false;
      state.filePreview= null;
      state.filePreviewModel= false;
      state.loader= false;
    }
  },
});

export default generalSlice.reducer;
export const {
  setSideBarAction,
  setNetworkOffline,
  updatePageTitle,
  updateLoaderStatus,
  updateURLParams,
  setLoginSection,
  setToastStatus,
  toastData,
  setDeviceWidth,
  setTableLoader,
  setDisabledButton,
  setFilePreview,
  setFilePreviewModel,
  setFileData,
  setBulkSupportingDocumentData,
  setSupportingDocumentData,
  setSupportingDocumentPlaceholder,
  setSupportingDocumentProgress,
  setProDocDefault,
  setClearGeneralState
} = generalSlice.actions;
