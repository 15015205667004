import { configureStore } from '@reduxjs/toolkit';
import auth from './auth/reducer';
import general from './general/reducer';
import admin from './admin/reducer';
import table from './table/reducer';
import file from './file/reducer';
import location from './location/reducer';
import product from './product/reducer';
import category from './category/reducer';
import subCategory from './sub-category/reducer';
import organization from './organization/reducer';
import agent from './agent/reducer';
import customer from './customer/reducer';
import trade from './trade/reducer';

export const store = configureStore({
  reducer: {
    auth,
    general,
    admin,
    table,
    file,
    location,
    product,
    category,
    subCategory,
    organization,
    agent,
    customer,
    trade
  },
  //  middleware: [thunk, logger],
})

