// Import files and libraries
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';


//others
import { IconBack, IconEdit, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData } from '../../../store/location/reducer';
import { setDisabledButton, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionCreate, actionUpdate } from '../../../store/location/action'


// custom component
import { PERMISSION_KEY, PERMISSION_PAGE_KEY, validatePermission } from '../../../config/constant';
import { VILLAGE_LIST, TOWN_LIST, SUBCOUNTY_LIST, COUNTY_LIST, STATE_LIST } from '../../../config/location';
import FormStatus from '../../../component/form-status';

const LocationFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState({});
    const [editForm, setEditForm] = useState(false);

    const { formData, updateId, formModel } = useSelector((state) => state.location);
    const { disabledButton } = useSelector((state) => state.general);
    const { agentList } = useSelector((state) => state.agent);
    const { defaultUserPermission } = useSelector((state) => state.auth);

    useEffect(() => {
        setErrors({});
        setEditForm(updateId ? false : true);
    }, [formModel]);

    const numberRegExp = /^[0-9]+(\.[0-9]{1,2})?$/;

    // form validation schema
    let schema = Yup.object().shape({
        location_name: Yup.string()
            .max(50, 'Location name must be at most 50 characters long')
            .required('Location name is required'),
        address: Yup.string()
            .max(255, 'Address must be at most 255 characters long')
            .required('Address is required'),
        village: Yup.string()
            .required('Village is required'),
        town: Yup.string()
            .required('Town is required'),
        sub_country: Yup.string()
            .required('Sub country is required'),
        country: Yup.string()
            .required('Country is required'),
        state: Yup.string()
            .required('State is required')
    });

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            if (updateId) { //check the form action update or create
                // update data with update id
                dispatch(actionUpdate(formData, updateId));
            } else {
                // create form data
                dispatch(actionCreate(formData));
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        let data = {
            ...formData,
            [name]: value,
        };

        dispatch(setFormData(data));
        validateField(name, value);
    };

    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-10 mb-3">
                        <div className='d-flex align-items-center'>
                            <IconBack
                                width={32}
                                height={32}
                                onIconClick={closeModal}
                                customClass='cursor-pointer'
                            />
                            <h3 className='ms-2 flex-shrink-1 mb-0'>{updateId ? editForm ? 'Update Location' : 'View Location' : 'Create New Location'}</h3>
                        </div>
                    </div>
                    {updateId && defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.LOCATION, PERMISSION_KEY.UPDATE) && (
                        <div className="col-md-2 mb-3 text-end">
                            <button
                                className="btn  btn-purple-primary me-3"
                                type='button'
                                onClick={e => setEditForm(true)}
                            >
                                <IconEdit customClass='text-white' /> Edit
                            </button>
                        </div>
                    )}
                </div>

                <div className="col-12 mt-3">
                    <Accordion defaultActiveKey={['0']} alwaysOpen className='custom-accordiant'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Location information</Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Location Name</label>
                                            <input type="text" className="form-control "
                                                name="location_name"
                                                disabled={!editForm}
                                                value={formData.location_name}
                                                onChange={handleChange}
                                            />
                                            {errors.location_name && <span className="error-message">{errors.location_name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Default Agent</label>
                                            <Dropdown
                                                value={formData.agent_id}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={agentList}
                                                optionLabel="full_name"
                                                optionValue="id"
                                                placeholder="Select a agent"
                                                className="w-100 custom-single-dropdown"
                                                name='agent_id'
                                            />
                                            {/* {errors.user_id && <span className="error-message">{errors.user_id}</span>} */}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-8">
                                        <div className="form-group mb-3">
                                            <label>Address</label>
                                            <textarea className="form-control "
                                                name="address"
                                                disabled={!editForm}
                                                value={formData.address}
                                                onChange={handleChange}
                                                rows={3}
                                            >
                                                {formData.address}
                                            </textarea>
                                            {errors.address && <span className="error-message">{errors.address}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Village</label>
                                            <Dropdown
                                                value={formData.village}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={VILLAGE_LIST}
                                                optionLabel="name"
                                                optionValue="id"
                                                placeholder="Select a village"
                                                className="w-100 custom-single-dropdown"
                                                name='village'
                                            />
                                            {errors.village && <span className="error-message">{errors.village}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Town</label>
                                            <Dropdown
                                                value={formData.town}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={TOWN_LIST}
                                                optionLabel="name"
                                                optionValue="id"
                                                placeholder="Select a town"
                                                className="w-100 custom-single-dropdown"
                                                name='town'
                                            />
                                            {errors.town && <span className="error-message">{errors.town}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>State</label>
                                            <Dropdown
                                                value={formData.state}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={STATE_LIST}
                                                optionLabel="name"
                                                optionValue="id"
                                                placeholder="Select a state"
                                                className="w-100 custom-single-dropdown"
                                                name='state'
                                            />
                                            {errors.state && <span className="error-message">{errors.state}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Sub Country</label>
                                            <Dropdown
                                                value={formData.sub_country}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={SUBCOUNTY_LIST}
                                                optionLabel="name"
                                                optionValue="id"
                                                placeholder="Select a sub country"
                                                className="w-100 custom-single-dropdown"
                                                name='sub_country'
                                            />
                                            {errors.sub_country && <span className="error-message">{errors.sub_country}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Country</label>
                                            <Dropdown
                                                value={formData.country}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={COUNTY_LIST}
                                                optionLabel="name"
                                                optionValue="id"
                                                placeholder="Select a country"
                                                className="w-100 custom-single-dropdown"
                                                name='country'
                                            />
                                            {errors.country && <span className="error-message">{errors.country}</span>}
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className='col-12 mb-3'>
                    <FormStatus
                        onStatusClick={handleChange}
                        active={formData.active}
                        id={updateId}
                        editForm={editForm}
                    />
                </div>
                <Divider />
                <div className="col-12 text-end">
                    <div className="form-group mb-3">
                        {editForm && (
                            <>
                                <button
                                    className="btn  btn-purple-primary-outline me-3"
                                    type='button'
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    disabled={disabledButton}
                                    className="btn  btn-purple-primary"
                                    type='submit'
                                >
                                    {disabledButton ? <Spinner /> : null} {updateId ? 'Update' : 'Create'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </form >
        </>
    );
};


export default LocationFormModel;
