import React from 'react';
import { Routes, Route } from "react-router-dom";

import PublicRoutes from "./public-routes";
import ProtectedRoutes from "./protected-routes";

//get all pages
import LoginPage from "../../pages/public/login";
import ResetPage from "../../pages/public/reset-password";
import DashboardPage from "../../pages/protected/dashboard";

import PageNotFoundPage from "../../pages/error/page-not-found";

import AdminPage from "../../pages/protected/admin";
import LocationPage from "../../pages/protected/location";
import ProductPage from "../../pages/protected/product";
import OrganizationPage from "../../pages/protected/organization";
import AgentPage from "../../pages/protected/agent";
import CustomerPage from "../../pages/protected/customer";
import TradingPage from "../../pages/protected/trade";
import TradingDetailsPage from "../../pages/protected/trade/trade-details";
import TradingItemApprovalPage from "../../pages/protected/trade/item-approval";


const PortalRoutes = () => (
  
  <Routes>
    {/* Public routes */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/" element={<LoginPage />} />
    </Route>
    <Route path="reset-password" element={<PublicRoutes page="reset-password" />}>
      <Route path="/reset-password" element={<ResetPage />} />
    </Route>

    {/* Protected routes */}
    <Route path="dashboard" element={<ProtectedRoutes path="dashboard" />}>
      <Route path="/dashboard" element={<DashboardPage />} />
    </Route>
    <Route path="portal/admin" element={<ProtectedRoutes path="portal/admin" />}>
      <Route path="/portal/admin" element={<AdminPage />} />
    </Route>
    <Route path="portal/locations" element={<ProtectedRoutes path="portal/locations" />}>
      <Route path="/portal/locations" element={<LocationPage />} />
    </Route>
    <Route path="portal/products" element={<ProtectedRoutes path="portal/products" />}>
      <Route path="/portal/products" element={<ProductPage />} />
    </Route>
    <Route path="portal/organisation" element={<ProtectedRoutes path="portal/organisation" />}>
      <Route path="/portal/organisation" element={<OrganizationPage />} />
    </Route>
    <Route path="portal/agents" element={<ProtectedRoutes path="portal/agents" />}>
      <Route path="/portal/agents" element={<AgentPage />} />
    </Route>
    <Route path="portal/customer" element={<ProtectedRoutes path="portal/customer" />}>
      <Route path="/portal/customer" element={<CustomerPage />} />
    </Route>
    <Route path="portal/trading" element={<ProtectedRoutes path="portal/trading" />}>
      <Route path="/portal/trading" element={<TradingPage />} />
    </Route>
    <Route path="portal/trading/details" element={<ProtectedRoutes path="portal/trading/details" />}>
      <Route path="/portal/trading/details" element={<TradingDetailsPage />} />
    </Route>
    <Route path="portal/trading/item-approval" element={<ProtectedRoutes path="portal/trading/item-approval" />}>
      <Route path="/portal/trading/item-approval" element={<TradingItemApprovalPage />} />
    </Route>

    {/* Page not found */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/*" element={<PageNotFoundPage />} />
    </Route>
  </Routes>
);

export default PortalRoutes;
