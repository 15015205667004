import React from 'react';
import { currencyFormatValue, fixedTwoDigit, roundUpValue } from '../helper/common-helper';
import { ORDER_ITEM_STATUS } from '../config/constant';

const TradeItemTable = ({ items = [], cc = 0, mc = 0,onRowSelected }) => {
    
    let productItems = [];

    items.map(item => {
        let itemStatus = "";
        switch (item.order_item_status) {
            case ORDER_ITEM_STATUS.NEW.id:
                itemStatus = <span className='badge badge-active'>{ORDER_ITEM_STATUS.NEW.text}</span>
                break;
            case ORDER_ITEM_STATUS.AGENT_REJECTED.id:
                itemStatus = <span className='badge badge-inactive'>{ORDER_ITEM_STATUS.AGENT_REJECTED.text}</span>
                break;
            case ORDER_ITEM_STATUS.WAITING_ADMIN_REVIEW.id:
                itemStatus = <span className='badge badge-active'>{ORDER_ITEM_STATUS.WAITING_ADMIN_REVIEW.text}</span>
                break;
            case ORDER_ITEM_STATUS.ADMIN_REJECTED.id:
                itemStatus = <span className='badge badge-inactive'>{ORDER_ITEM_STATUS.ADMIN_REJECTED.text}</span>
                break;
            case ORDER_ITEM_STATUS.WAITING_SUPER_ADMIN_REVIEW.id:
                itemStatus = <span className='badge badge-active'>{ORDER_ITEM_STATUS.WAITING_SUPER_ADMIN_REVIEW.text}</span>
                break;
            case ORDER_ITEM_STATUS.REJECTED.id:
                itemStatus = <span className='badge badge-inactive'>{ORDER_ITEM_STATUS.REJECTED.text}</span>
                break;
            case ORDER_ITEM_STATUS.APPROVED.id:
                itemStatus = <span className='badge badge-active'>{ORDER_ITEM_STATUS.APPROVED.text}</span>
                break;
        }
        productItems.push({
            ...item,
            itemStatus
        });
    });



    return (
        <table className='table single-line'>
            <thead className='table-light'>
                <tr>
                    <th>TRADING ITEM ID</th>
                    <th>METAL/GEM STONE</th>
                    <th>CERTIFICATION CHARGES</th>
                    <th>CUSTOMER CONTRIBUTION</th>
                    <th>M/C CONTRIBUTION</th>
                    <th>LME Value</th>
                    <th>STATUS</th>
                </tr>
            </thead>
            <tbody>
                {productItems.length > 0 && productItems.map((item, inx) => (
                    <tr key={inx + "tr"} className='cursor-pointer' onClick={e => onRowSelected(item.slug,inx)}>
                        <td>{item.row_ref_number}</td>
                        <td>{item.category_name}</td>
                        <td className='text-end'>{item.certification_charges} KSH</td>
                        <td className='text-end'>{cc}%</td>
                        <td className='text-end'>{mc}%</td>
                        <td className='text-end'>
                            {
                                currencyFormatValue(
                                    fixedTwoDigit(
                                        fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
                                        -
                                        (
                                            fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
                                            *
                                            fixedTwoDigit(item.loyalty / 100)
                                        )
                                    )
                                )
                            } KSH
                        </td>
                        <td>{item.itemStatus}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TradeItemTable;
