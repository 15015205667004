// get axios api config
import api from './api';

// get axios options
import { axiosOptions, axiosOptionsForFormData } from '../helper/common-helper';
import { store } from '../store/store';
import { setSupportingDocumentProgress } from '../store/general/reducer';
import { setItemSupportingDocumentProgress } from '../store/trade/reducer';


// export the functions
export default {
    // create user
    async upload(data, from, inx) {
        const axiosConfig = await axiosOptionsForFormData();
        axiosConfig.onUploadProgress = (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            switch (from) {
                case 'admin':
                    store.dispatch(setSupportingDocumentProgress({ inx, percentCompleted }));
                    break;
            }
        };
        return await api().post('service-file/', data, axiosConfig);
    },
    async uploadItemDoc(data, inx, itemInx) {
        const axiosConfig = await axiosOptionsForFormData();
        axiosConfig.onUploadProgress = (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            
            store.dispatch(setItemSupportingDocumentProgress({ inx, percentCompleted, itemInx }));
        };
        return await api().post('service-file/', data, axiosConfig);
    },

    // update user
    async deleteById(id) {
        const axiosConfig = await axiosOptions();
        return await api().delete('service-file/' + id, axiosConfig);
    },
    async getById(id) {
        const axiosConfig = await axiosOptions();
        return await api().get('service-file/' + id, axiosConfig);
    },
}
