import React from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import {useSelector} from 'react-redux'
import { DEFAULT_USER_ROLE } from '../../config/constant';

const PublicRoutes = ({page=null}) => {
    
    const {isAuth,userData} = useSelector(state => state.auth);

        return isAuth ? userData.user_role_id === DEFAULT_USER_ROLE.CUSTOMER ? <Navigate to='/trading' />: <Navigate to='/portal/trading' /> : <Outlet />
    // }

    // if(isAuth){
    //     if(userData.user_role_id === DEFAULT_USER_ROLE.CUSTOMER){

    //     }else{

    //     }
    // }
}

export default PublicRoutes;