import React from 'react';
import { IconWebLogo } from '../svg-icons';
const GeneralTopMenu = () => {   
  return (
  <div className='container-fluid bg-custom-white pt-2 pb-2'>
      <IconWebLogo width={150} height={60} />
  </div>
  );
};

export default GeneralTopMenu;