//reducer
import { setUpdateId, setFormData, setFormModel, setNationalIdResponse, setDefaultFormData } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton, setBulkSupportingDocumentData, setProDocDefault, setFileData } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import customerService from '../../services/customer';
import { formatFileSize, truncateFileName } from "../../helper/common-helper";
import { actionGetFileById } from "../general/action";
import { actionGetAgentList } from "../agent/action";


// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {

        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.CUSTOMER,PERMISSION_KEY.CREATE)){
        //     return;
        // }

        const response = await customerService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.CREATE) {
            await dispatch(actionGetAll());
            await dispatch(setFormModel(false));
            // clear the form details
            dispatch(setDefaultFormData());
            dispatch(setProDocDefault());
            // remove the update id
            dispatch(setUpdateId(null));

            showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');
        }
    }
}

// export user update action
export const actionUpdate = (data, id) => {
    return async (dispatch, getState) => {

        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.CUSTOMER,PERMISSION_KEY.UPDATE)){
        //     return;
        // }

        const response = await customerService.update(data, id);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.UPDATE) {
            await dispatch(actionGetAll());
            await dispatch(setFormModel(false));
            showToast(TOAST_TYPE.SUCCESS, 'Successfully updated', 'top-right');
        }
    }
}

// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {

        const {defaultUserPermission} = getState().auth;
        if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.CUSTOMER,PERMISSION_KEY.READ)){
            return;
        }

        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset ,
            limit: getParams.limit,
        };
        const response = await customerService.getAll(data);
        // dispatch(updateLoaderStatus(false));
        dispatch(setTableLoader(false));
        if (response?.status === HTTP_STATUS_CODE.GET) {
            await dispatch(setTableData({
                data: response.data.data.customers,
                totalRecord: response.data.data.total_count,
                tableKey: TABLES.USER
            }));
        }
    }
}

// export user get by id action
export const actionGetById = (id) => {
    return async (dispatch, getState) => {

        // const {defaultUserPermission} = getState().auth;
        // if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.CUSTOMER,PERMISSION_KEY.READ)){
        //     return;
        // }

        const response = await customerService.getById(id);
        if (response?.status === 200) {
            const data = response.data.data.customer;

            let supportingDocuments = {};
            if (data?.supporting_documents && (data.supporting_documents).length > 0) {
                data.supporting_documents.forEach(file => {

                    let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;

                    let fileDetails = {
                        ...file,
                        size: formatFileSize(file.file_size),
                        name: truncateFileName(file.original_name)
                    };

                    supportingDocuments[inx] = fileDetails;
                });

                dispatch(setBulkSupportingDocumentData(supportingDocuments));

            }
            await dispatch(actionGetAgentList(data.organization_id));
            await dispatch(setUpdateId(id));
            await dispatch(setFormData({
                first_name: data.first_name,
                middle_name: data.middle_name,
                last_name: data.last_name,
                national_id: data.national_id,
                email: data.email,
                phone_number: data.phone_number,
                citizen_ship: data.citizen_ship,
                cladfy_request_id: data.cladfy_request_id,
                serial_number: data.serial_number,
                dob: data.dob,
                gender: data.gender,
                marital_status: data.marital_status,
                occupation: data.occupation,
                nominee_name: data.nominee_name,
                nominee_relationship: data.nominee_relationship,
                nominee_phone_number: data.nominee_phone_number,
                address: data.address,
                remarks: data.remarks,
                location_id: data.location_id,
                organization_id: data.organization_id,
                agent_id: data.agent_id,
                active: data.active,
                wallet_payment: 0
            }));
            if (data.profile) {
                await dispatch(actionGetFileById(data.profile.id, 'profile'));
            }else{
                await dispatch(setFileData({name:'profile',value:null}));
                
            }
            dispatch(updateLoaderStatus(false));
            dispatch(setFormModel(true));

        }
    }
}

export const actionValidateNationalId = (nationalId) => {
    return async (dispatch, getState) => {

        await dispatch(updateLoaderStatus(true));
        const response = await customerService.validateNationalId(nationalId);
        await dispatch(updateLoaderStatus(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            dispatch(setNationalIdResponse(response.data.data.cladfy.data));
        }
    }
}