export const VILLAGE_LIST = [
    'Ebuhando',
    'Ebunyingi',
    'Gituamba',
    'Gitamaiyu',
    'Marigat',
    'Metkei',
    'Gede',
    'Kizingo',
];
export const TOWN_LIST = [
    'Nairobi',
    'Mombasa',
    'Kisumu',
    'Nakuru',
    'Eldoret',
    'Thika',
    'Malindi',
    'Naivasha',
    'Machakos',
    'Kitale',
    'Garissa',
    'Meru',
    'Kakamega',
    'Nyeri',
    'Embu',
];
export const SUBCOUNTY_LIST = [
    'Westlands',
    'Starehe',
    'Kamukunji',
    'Lang\'ata',
    'Embakasi',
    'Kiambu',
    'Limuru',
    'Thika',
    'Githunguri',
    'Likoni',
    'Changamwe',
    'Kisauni',
    'Nyali',
    'Mvita',
    'Lugari',
    'Likuyani',
    'Mumias West',
    'Matungu'
];
export const COUNTY_LIST = [
    'Baringo',
    'Bomet',
    'Bungoma',
    'Busia',
    'Elgeyo Marakwet',
    'Embu',
    'Garissa',
    'Homa Bay',
    'Isiolo',
    'Kajiado',
    'Kakamega',
    'Kericho',
    'Kiambu',
    'Kilifi',
    'Kirinyaga',
    'Kisii',
    'Kisumu',
    'Kitui',
    'Kwale',
    'Laikipia',
    'Lamu',
    'Machakos',
    'Makueni',
    'Mandera',
    'Marsabit',
    'Meru',
    'Migori',
    'Mombasa',
    'Murang\'a',
    'Nairobi',
    'Nakuru',
    'Nandi',
    'Narok',
    'Nyamira',
    'Nyandarua',
    'Nyeri',
    'Samburu',
    'Siaya',
    'Taita-Taveta',
    'Tana River',
    'Tharaka Nithi',
    'Trans Nzoia',
    'Turkana',
    'Uasin Gishu',
    'Vihiga',
    'Wajir',
    'West Pokot'
];
export const STATE_LIST = [
    'Baringo',
    'Bomet',
    'Bungoma',
    'Busia',
    'Elgeyo Marakwet',
    'Embu',
    'Garissa',
    'Homa Bay',
    'Isiolo',
    'Kajiado',
    'Kakamega',
    'Kericho',
    'Kiambu',
    'Kilifi',
    'Kirinyaga',
    'Kisii',
    'Kisumu'
];