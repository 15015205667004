import React from 'react';
import { Routes, Route } from "react-router-dom";

import PublicRoutes from "./public-routes";
import ProtectedRoutes from "./protected-routes";

//get all customer pages
import LoginPage from "../../pages/public/login";
import ResetPage from "../../pages/public/reset-password";
import DashboardPage from "../../pages/protected/dashboard";

import PageNotFoundPage from "../../pages/error/page-not-found";

// import TradingPage from "../../pages/customer-protected/trade";
// import TradingDetailsPage from "../../pages/customer-protected/trade/trade-details";
// import TradingItemApprovalPage from "../../pages/customer-protected/trade/item-approval";


const CustomerRoutes = () => (
  
  <Routes>
    {/* Public routes */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/" element={<LoginPage />} />
    </Route>
    <Route path="reset-password" element={<PublicRoutes page="reset-password" />}>
      <Route path="/reset-password" element={<ResetPage />} />
    </Route>

    {/* Protected routes */}
    <Route path="dashboard" element={<ProtectedRoutes path="dashboard" />}>
      <Route path="/dashboard" element={<DashboardPage />} />
    </Route>
    {/* <Route path="/trading" element={<ProtectedRoutes path="/trading" />}>
      <Route path="/trading" element={<TradingPage />} />
    </Route> */}
    {/* <Route path="/trading/details" element={<ProtectedRoutes path="/trading/details" />}>
      <Route path="/trading/details" element={<TradingDetailsPage />} />
    </Route> */}
    {/* <Route path="/trading/item-approval" element={<ProtectedRoutes path="/trading/item-approval" />}>
      <Route path="/trading/item-approval" element={<TradingItemApprovalPage />} />
    </Route> */}

    {/* Page not found */}
    <Route path="" element={<PublicRoutes />}>
      <Route path="/*" element={<PageNotFoundPage />} />
    </Route>
  </Routes>
);

export default CustomerRoutes;
