// Import files and libraries
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Dialog } from 'primereact/dialog';

// action
import { IconBack, IconSuccessCheckMark, Spinner } from '../../../svg-icons';
import { ORDER_STATUS, PERMISSION_KEY, PERMISSION_PAGE_KEY,  validatePermission } from '../../../config/constant';
import { actionDisbursal, actionGetById } from '../../../store/trade/action';
import TradeItemTable from '../../../component/trade-item-table';
import { useNavigate } from 'react-router-dom';
import GetOrderStatus from '../../../component/order-status';
import { displayOrderCreated } from '../../../helper/date-helper';
import ItemDetails from '../../../component/item-details';
import { setCurrentItem } from '../../../store/trade/reducer';
import PermissionDenied from '../../../component/permission-denied';


const TradingDetails = () => {
    // create a dispatch
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // create a state
    const [key, setKey] = useState('items');

    const {
        defaultUserPermission
    } = useSelector((state) => state.auth);

    const {
        tradeDetails
    } = useSelector((state) => state.trade);

    const {
        disabledButton,
        urlParams
    } = useSelector((state) => state.general);


    // On page function call get the page data and if any table action changes call the api
    useEffect(() => {
        if (urlParams?.view && urlParams?.type && urlParams.type === 'order') {
            dispatch(actionGetById(urlParams.view));
        }
    }, [urlParams]);

    const gotoItemApproval = (slug, inx) => {
        dispatch(setCurrentItem(tradeDetails.order_items[inx]));
        navigate("/portal/trading/item-approval?type=order-item&view=" + slug);
    }
    const closeModal = () => {
        navigate("/portal/trading");
    }
    const disbursal = () => {
        dispatch(actionDisbursal(tradeDetails.slug));
    }
    return (
        <>
            {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.READ) ? (

                <div className='container-fluid'>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-md-12 mb-3">
                                    <div className='d-flex align-items-center'>
                                        <IconBack
                                            width={32}
                                            height={32}
                                            onIconClick={closeModal}
                                            customClass='cursor-pointer'
                                        />
                                        <h3 className='ms-2 flex-shrink-1 mb-0'>Trading ID: {tradeDetails && tradeDetails.row_ref_number}</h3>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <p className='m-0 sub-page-title'>Date: {tradeDetails && displayOrderCreated(tradeDetails.created_at)}</p>
                                    <p className='m-0 sub-page-title'>Status: {tradeDetails && <GetOrderStatus status={tradeDetails.order_status} />}</p>
                                    <p className='m-0 mt-2'>Download Receipt</p>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <Tabs
                                        id="item-details-tab"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="mb-3"
                                    >
                                        <Tab eventKey="items" title="Items">
                                            {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.DISBURSAL) && tradeDetails && tradeDetails.order_status === ORDER_STATUS.DISBURSAL.id && (
                                                <div className='form-group text-end mb-3'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-purple-primary'
                                                    onClick={disbursal}
                                                    >Disbursal</button>
                                                </div>
                                            )}
                                            {
                                                tradeDetails && (
                                                    <TradeItemTable
                                                        items={tradeDetails.order_items}
                                                        cc={tradeDetails.customer_contribution}
                                                        mc={tradeDetails.mc_contribution}
                                                        onRowSelected={gotoItemApproval}
                                                    />
                                                )
                                            }
                                        </Tab>

                                        <Tab eventKey="details" title="Details">
                                            <div className='row trade-customer-section mt-5'>
                                                <h5><strong>Customer Information</strong></h5>

                                                <div className='col-md-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Full Name</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && `${tradeDetails.customer_first_name} ${tradeDetails.customer_middle_name}${tradeDetails.customer_last_name}`}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>National Identity Number</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_national_id}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Serial Number</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_serial_number}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Phone Number</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_phone_number}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Gender</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_gender}</label>
                                                    </div>
                                                </div>

                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Citizenship</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_citizen_ship}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Date of Birth</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_dob}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Marital Status</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_marital_status}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Address</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_address}</label>
                                                    </div>
                                                </div>

                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Nominee Name</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_nominee_name}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Nominee Relationship</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_nominee_relationship}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Nominee Phone Number</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_nominee_phone_number}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Remarks</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_remarks}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Occupation</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_occupation}</label>
                                                    </div>
                                                </div>
                                                <div className='clearfix'></div>

                                                <h5 className='mt-5'><strong>Agent Information</strong></h5>

                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Location</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.location_name}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Agent</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && `${tradeDetails.agent_first_name} ${tradeDetails.agent_middle_name}${tradeDetails.agent_last_name}`}</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Organization</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.organization_name}</label>
                                                    </div>
                                                </div>
                                                <div className='clearfix'></div>

                                                <h5 className='mt-5'><strong>Wallet Contribution</strong></h5>

                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Customer Contribution</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_contribution}%</label>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Madini Chapaa Contribution</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.mc_contribution}%</label>
                                                    </div>
                                                </div>

                                                <div className='clearfix'></div>

                                                <h5 className='mt-5'><strong>Calculation</strong></h5>
                                                <div className='col-12'>
                                                    {tradeDetails && <ItemDetails cc={tradeDetails.customer_contribution} mc={tradeDetails.mc_contribution} items={tradeDetails.order_items} />}
                                                </div>
                                            </div>
                                        </Tab>
                                        {tradeDetails && tradeDetails.order_status === ORDER_STATUS.SETTLED && (
                                            <Tab eventKey="bankTransaction" title="Bank Transaction">
                                                <div className='row'>
                                                    <div className='col-5'>
                                                        <h5>Invoice Details</h5>
                                                        <table className='table' cellPadding={3}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Status</td>
                                                                    <td>Processed</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Transaction ID</td>
                                                                    <td>6525-6525-8545--****</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Account ID</td>
                                                                    <td>725e 256y ddd eeee</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reference Type</td>
                                                                    <td>AAA</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Reference ID</td>
                                                                    <td>84484 84848</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Transaction Date</td>
                                                                    <td>30th July, 2024</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Transaction Time</td>
                                                                    <td>2.34pm, (GMT)</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </div>
                            </div>
                            <Dialog
                                visible={false}
                                modal
                                onHide={() => {
                                    console.log('called dialog popup')
                                }}
                                content={({ hide }) => (
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='form-group text-center'>
                                                <IconSuccessCheckMark width={80} height={80} />
                                            </div>
                                            <div className='form-group text-center m-5'>
                                                Paymnet successfully sent to the customer
                                            </div>
                                            <div className='form-group text-center'>
                                                <button
                                                    className="btn  btn-purple-primary"
                                                    type='button'
                                                // onClick={paymentDialog}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            ></Dialog>
                            <Dialog
                                header="Approved Order Items"
                                // position='top'
                                visible={false}
                                style={{ width: '75vw' }}
                                onHide={() => {
                                    // dispatch(setConfirmPaymentModel(false));
                                }}
                                maximizable
                                className='approved-item-list'
                            >

                                <div className='form-group mt-5 text-end mb-3'>
                                    <button
                                        className="btn  btn-purple-primary-outline me-3"
                                        type='button'
                                        onClick={(e) => {
                                            // setShowApprovedItem(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={disabledButton}
                                        className="btn  btn-purple-primary"
                                        type='button'
                                    // onClick={makePayment}
                                    >
                                        {disabledButton ? <Spinner /> : null} Proceed to payment
                                    </button>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            ) : defaultUserPermission && <PermissionDenied />}
        </>
    );
}


export default TradingDetails;
