import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { setSortField, setSortOrder, setLimit, setOffset, setContaxtMenuRef } from '../store/table/reducer';
import { SIDEBAR_OPTION } from '../config/constant';


const DataTableComponent = ({ tableKey = 'jay', columns = [],  onTableRowSelect }) => {

    const dispatch = useDispatch();
    const { sort, order, limit, offset, totalRecords, data, contextMenuRef } = useSelector(state => state.table[tableKey]);
    const { sideBarActive, deviceWidth, tableLoader } = useSelector(state => state.general);

    const [tableWidth, setTableWidth] = useState(sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? `${deviceWidth - 148}px` : `${deviceWidth <= 768 ? deviceWidth - 148 : deviceWidth - 306}px`);

    useEffect(() => {
        setTableWidth(sideBarActive === SIDEBAR_OPTION.ICON_SIDEBAR ? `${deviceWidth - 148}px` : `${deviceWidth <= 768 ? deviceWidth - 148 : deviceWidth - 306}px`);
    }, [sideBarActive, deviceWidth]);

    const handleSort = (event) => {

        dispatch(setSortField({ tableKey, value: event.sortField }));
        dispatch(setSortOrder({ tableKey, value: event.sortOrder === 1 ? 'ASC' : 'DESC' }));
    };

    const handlePage = (event) => {

        dispatch(setOffset({ tableKey, value: event.first }));
        dispatch(setLimit({ tableKey, value: event.rows }));
    };

    // const cm = useRef(null);

    const setContextMenuOption = (opt) => {
        dispatch(setContaxtMenuRef({ opt, tableKey }))
    }

    return (
        <>
            <DataTable
                onRowSelect={e => onTableRowSelect(e)}
                emptyMessage={<div className="text-center">No data available</div>}
                onContextMenuSelectionChange={(e) => setContextMenuOption(e.value)}
                className={sideBarActive ? `custom-datatable active` : 'custom-datatable'}
                style={{ width: tableWidth }}
                tableClassName="table single-line"
                showGridlines
                value={data}
                paginator
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport "
                first={offset}
                rows={limit}
                totalRecords={totalRecords}
                rowsPerPageOptions={[5, 10, 25, 50]}
                // currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
                sortField={sort}
                sortOrder={order === 'ASC' ? 1 : -1}
                onSort={handleSort}
                onPage={handlePage}
                lazy
                loading={tableLoader}
                selectionMode="single"
            >
                {columns.map((col, i) => (
                    <Column
                        key={i}
                        body={col.body || null}
                        headerClassName={`table-light ${col.headerAlign}`}
                        bodyClassName={col.bodyAlign}
                        sortable={col.sortable}
                        field={col.field}
                        header={col.header}
                    />
                ))}
            </DataTable>
        </>
    );
};

export default DataTableComponent;
