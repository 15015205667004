

// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

import { RECORD_STATUS } from '../../config/constant';

const defaultFormData = {
  location_name: "",
  address: "",
  village: "",
  town: "",
  sub_country: "",
  country: "",
  state: "",
  agent_id: "",
  active: RECORD_STATUS.ACTIVE.id
};

// user default initial state
const initialState = {
  formData: defaultFormData,
  formModel: false,
  updateId:null,
  locationList:[]
}

// create a slice and reducers
const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
    },
    setLocationList: (state, action) => {
      state.locationList = action.payload;
    },
    setClearLocationState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
      state.locationList = [];
    }
   
  },
})
// export the user module
export default locationSlice.reducer;

export const {
  setFormModel,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setLocationList,
  setClearLocationState
} = locationSlice.actions;