import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../../store/auth/actions';

import { updatePageTitle } from '../../../store/general/reducer';

import { IconEyeVisible, IconEyeVisibleOff, IconMsgSuccessCheckMark, Spinner } from '../../../svg-icons';
import { encryptValue } from '../../../helper/encryption-helper';
import { setDisabledButton } from '../../../store/general/reducer';
import { setDefaultFormData, setFormData, setShowForgotPassword, setShowForgotPasswordMsg, setShowLogin } from '../../../store/auth/reducer';
import { REGEXP } from '../../../config/constant';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordPage from './forgot-password';

const LoginPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { disabledButton } = useSelector((state) => state.general);
  const { formData, showLogin, showForgotPassword, showForgotPasswordMsg } = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState({});


  const schema = Yup.object().shape({
    email: Yup.string()
      .matches(REGEXP.emailRegExp, 'Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const validateField = async (name, value) => {
    try {
      await Yup.reach(schema, name).validate(value);
      setErrors({ ...errors, [name]: '' });
    } catch (validationError) {
      setErrors({ ...errors, [name]: validationError.message });
    }
  };
  const validateForm = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const newErrors = validationErrors.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = await validateForm();

    if (isValid) {
      const finalData = {
        email: formData.email,
        password: encryptValue(formData.password)
      }
      dispatch(setDisabledButton(true));
      dispatch(login(finalData));
    } else {
      console.log('Form has errors:', errors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = {
      ...formData,
      [name]: value
    }
    dispatch(setFormData(data));
    validateField(name, value);
  };

  useEffect(() => {
    let pageTitle = showLogin ? 'Login' : 'Forgot password';
    dispatch(updatePageTitle(pageTitle));
  }, [showLogin]);


  // go to the forgot password section
  const forgotPasswordSection = async () => {
    dispatch(setShowLogin(false));
    dispatch(setShowForgotPassword(true));
    dispatch(setShowForgotPasswordMsg(false));
  }
  const toggleShowHidePassword = () => {
    setShowPassword(!showPassword);
  }

  const gotoRegister = () => {
    navigate('/register');
  }
   // go to the forgot password section
   const showLoginSection = async () => {
    dispatch(setShowLogin(true));
    dispatch(setShowForgotPassword(false));
    dispatch(setShowForgotPasswordMsg(false));
    dispatch(setDefaultFormData());
  }

  return (
    <div className="container-fluid">
      <div className={`row fit-height ${showForgotPasswordMsg ? "bg-msg-box":""}`}>
        <div className='col-md-6 align-self-center'>
          {/* Login */}
          {
            showLogin && (
              <form onSubmit={handleSubmit} noValidate>
                <h3 className='text-center'>Welcome back!</h3>
                <p className='text-center mb-5'>Enter your Credentials to access your account</p>
                <div className='login-section-pd'>
                  <div className="form-group mb-3">
                    <label>Email address</label>
                    <input type="email" className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <span className="error-message">{errors.email}</span>}
                  </div>
                  <div className="form-group mb-3">
                    <label>Password</label>
                    <div className="remove-dd input-group mb-3">
                      <input type={showPassword ? "text" : "password"} className="form-control"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        onClick={toggleShowHidePassword}
                      >{showPassword ? <IconEyeVisibleOff /> : <IconEyeVisible />}</span>
                    </div>
                    {errors.password && <span className="error-message">{errors.password}</span>}
                  </div>
                  <div className="form-group">
                    <p onClick={forgotPasswordSection} className="text-center cursor-pointer text-muted-one text-underline fnt-size-14 ">Forgot password?</p>

                  </div>
                  <div className="form-group text-center">

                    <button disabled={disabledButton} type="submit" className="btn-block btn btn-orange-primary">   {disabledButton ? <Spinner /> : null} Sign in</button>

                  </div>

                  <p className='line-login mt-3 position-relative text-center'>
                    <span className='fnt-size-12'>Or</span>
                  </p>
                </div>
                <p className='text-center mt-5'>Don't have an account?  <span className='text-orange cursor-pointer' onClick={gotoRegister}>Sign Up</span></p>
              </form>
            )
          }
          {/* forgot password */}
          {
            showForgotPassword && (<ForgotPasswordPage />)
          }
          {/* forgot password message */}
          {showForgotPasswordMsg && (
            <div className='form-group text-center'>
            <IconMsgSuccessCheckMark
            width={130}
            height={130}
            />
            <h6 className='m-0 mt-3'><strong>Mail Sent Successfully !!!</strong></h6>
            <p className='text-muted-one fnt-size-14'>Ready!Check the inbox for the email address you entered. You have received an email with a link where you can reset and set a new password.</p>
            <button onClick={showLoginSection} type="button" className="btn-block btn btn-orange-primary">Sign in</button>
            </div>
          )}
        </div>
        <div className={`col-md-6 ${showForgotPasswordMsg ? "bg-image-two" : "bg-image-one"}`}></div>
      </div>
    </div>
  );
};
export default LoginPage;

