// Import files and libraries
import React, { useEffect, useState } from 'react';
import { IconBack, IconCurrentStep, IconRejectStep, Spinner } from '../../../svg-icons';
import GetOrderItemStatus from '../../../component/order-item-status';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setComment, setCurrentItem, setShowComment } from '../../../store/trade/reducer';
import { ATTACHMENT_TYPE, DEFAULT_USER_ROLE, ORDER_ITEM_AR_STATUS, TOAST_TYPE } from '../../../config/constant';
import ItemDetails from '../../../component/item-details';
import { Dialog } from 'primereact/dialog';
import { setDisabledButton, setSupportingDocumentPlaceholder, updateLoaderStatus } from '../../../store/general/reducer';
import { actionGetOrderItemById, actionUpdateItemStatus } from '../../../store/trade/action';
import * as Yup from 'yup';
import { displayOrderCreated } from '../../../helper/date-helper';
import { showToast } from '../../../helper/toast-service-helper';
import MultipleFilePreview from '../../../component/multiple-file-upload';
import FilePreview from '../../../component/file-preview';
import { actionFileUpload } from '../../../store/general/action';
import { formatFileSize, getFileExtension } from '../../../helper/common-helper';

const TradingItemApproval = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showARButton, setShowARButton] = useState(false);
    const [actionStatus, setActionStatus] = useState(null);
    const [errors, setErrors] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    const {
        currentItem, showComment, comments
    } = useSelector((state) => state.trade);

    const {
        userData
    } = useSelector((state) => state.auth);

    const {
        disabledButton,
        urlParams,
        customer_photo,
        metal_photo,
        weight_photo,
        purity_photo,
        supportingDocuments
    } = useSelector((state) => state.general);

    // form validation schema
    let schema = Yup.object().shape({
        comments: Yup.string()
            .required('Comments is required')

    });

    // On page function call get the page data and if any table action changes call the api
    useEffect(() => {
        if (urlParams?.view && urlParams?.type && urlParams.type === 'order-item') {
            dispatch(actionGetOrderItemById(urlParams.view));
        }
    }, [urlParams]);


    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate({ comments }, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    const handleChangeComment = (e) => {
        const { name, value } = e.target;

        dispatch(setComment(value));
        validateField(name, value);
    }
    const goBack = () => {
        dispatch(setCurrentItem(null));
        navigate(-1); // Navigate to the previous page
    };

    useEffect(() => {
        if (currentItem) {
            generateARButton(currentItem.item_approval);

        }
    }, [currentItem]);

    const generateARButton = (ci) => {


        const checkAgent = ci.filter(ap => ap.user_role_id === 3 && ap.approval_status === 1);
        if (checkAgent.length > 0 && userData.user_role_id === 3) {
            console.log('agent')
            setShowARButton(true);
            return;
        }

        const checkAgentApproved = ci.filter(ap => ap.user_role_id === 3 && ap.approval_status === 2);
        const checkAdmin = ci.filter(ap => ap.user_role_id === 2 && ap.approval_status === 1);

        if (checkAdmin.length > 0 && checkAgentApproved.length > 0 && userData.user_role_id === 2) {
            console.log('admin')
            setShowARButton(true);
            return;
        }


        const checkAdminApproved = ci.filter(ap => ap.user_role_id === 2 && ap.approval_status === 2);
        const checkSuperAdminApproved = ci.filter(ap => ap.user_role_id === 1 && ap.approval_status === 1);
        if (checkAgentApproved.length > 0 && checkAdminApproved.length > 0 && checkSuperAdminApproved.length > 0 && userData.user_role_id === 1) {
            console.log('super admin')
            setShowARButton(true);
            return;
        }
    }

    const itemStatus = (status) => {
        let initHasErrors = false;
        if (userData.user_role_id === DEFAULT_USER_ROLE.AGENT) {
            if (!currentItem.customer_photo) {
                showToast(TOAST_TYPE.ERROR, `Please select the customer photo`, 'top-right');
                initHasErrors = true;
            }
            if (!currentItem.metal_photo) {
                showToast(TOAST_TYPE.ERROR, `Please select the metal photo`, 'top-right');
                initHasErrors = true;
            }
            if (!currentItem.weight_photo) {
                showToast(TOAST_TYPE.ERROR, `Please select the weight photo`, 'top-right');
                initHasErrors = true;
            }
            if (!currentItem.purity_photo) {
                showToast(TOAST_TYPE.ERROR, `Please select the purity photo`, 'top-right');
                initHasErrors = true;
            }
            if (initHasErrors) {
                return;
            }
        }
        setActionStatus(status);
        dispatch(setShowComment(true));

    }
    const updateOrderItemStatus = async () => {
        // get the form status
        const isValid = await validateForm();

        if (isValid) {
            let data = {
                order_item_id: currentItem.id,
                stage_id: (currentItem.item_approval).length,
                status: actionStatus,
                comments,
                customer_photo:customer_photo?.id,
                metal_photo:metal_photo?.id,
                weight_photo:weight_photo?.id,
                purity_photo:purity_photo?.id,
            };
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));
            dispatch(actionUpdateItemStatus(data, currentItem.slug, navigate));
        }
    }

    const uploadFile = (e) => {
        const { name } = e.target;
        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        if (name === 'customer_photo' || name === 'metal_photo' || name === 'weight_photo' || name === 'purity_photo') {
            const formData = new FormData();
            formData.append('file', checkFile);
            dispatch(actionFileUpload(formData, 0, name));
        } else {
            Array.from(e.target.files).forEach((file) => {
                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                let ext = getFileExtension(file.name);
                dispatch(setSupportingDocumentPlaceholder({
                    inx, file: {
                        name: file.name,
                        type: file.type,
                        ext,
                        size: formatFileSize(file.size),
                        isNew: true
                    }
                }));
                const formSDData = new FormData();
                formSDData.append('file', file); // Append the file to the FormData object

                dispatch(actionFileUpload(formSDData, inx, name));
            });
        }
    }


    return (
        <div className='container-fluid'>
            <div className="card mb-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-md-12 mb-3">
                            <div className='d-flex align-items-center'>
                                <IconBack
                                    width={32}
                                    height={32}
                                    onIconClick={goBack}
                                    customClass='cursor-pointer'
                                />
                                <h3 className='ms-2 flex-shrink-1 mb-0'>Trading Item: {currentItem && currentItem.order_item_row_ref_number}</h3>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <table className='table-borderless table-sm'>
                                <tbody>
                                    <tr>
                                        <td>Customer ID:</td>
                                        <td><a className='link'>{currentItem && currentItem.customer_row_ref_number}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Agent ID:</td>
                                        <td><a className='link'>{currentItem && currentItem.agent_row_ref_number}</a></td>
                                    </tr>
                                    <tr>
                                        <td>Trading ID:</td>
                                        <td>{currentItem && currentItem.row_ref_number}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{currentItem && <GetOrderItemStatus status={currentItem.order_item_status} />}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/* Stepper start */}
                        <div className="col-md-12 mt-3">
                            <div className='bg-stepper-line position-relative'>
                                <div className='row stepper'>
                                    <div className='col-md-4 text-center'>
                                        <p className='m-0 text-nowrap '>

                                            {
                                                currentItem && currentItem.item_approval.map((iap, inx) => {
                                                    if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REVIEW && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.AGENT) {

                                                        return <IconCurrentStep
                                                            key={inx + 'agent'}
                                                            width={25}
                                                            height={25}
                                                            customClass='top-2'
                                                        />;
                                                    } else if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.APPROVED && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.AGENT) {

                                                        return <IconCurrentStep
                                                            key={inx + 'agent'}
                                                            width={25}
                                                            height={25}
                                                            bgColor='#10B981'
                                                            customClass='top-2'
                                                        />;
                                                    } else if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REJECTED && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.AGENT) {

                                                        return <IconRejectStep
                                                            key={inx + 'agent'}
                                                            width={25}
                                                            height={25}
                                                            customClass='top-2'
                                                        />;
                                                    }
                                                })
                                            }

                                            <span className='ms-1' key={'m'}>Agent Verification</span>
                                        </p>
                                    </div>
                                    <div className='col-md-4'>

                                        {
                                            currentItem && currentItem.item_approval.map((iap, inx) => {
                                                // default orange
                                                if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REVIEW && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN) {
                                                    return (
                                                        <p className='m-0 text-nowrap '
                                                            key={inx + 'admin'}
                                                        >
                                                            <IconCurrentStep
                                                                customClass='top-2'
                                                                width={25}
                                                                height={25}
                                                            />
                                                            <span className='ms-1'>Admin Review</span>
                                                        </p>
                                                    )

                                                }

                                                //approved
                                                if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.APPROVED && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN) {
                                                    return (
                                                        <p className='m-0 text-nowrap '
                                                            key={inx + 'admin'}
                                                        >
                                                            <IconCurrentStep
                                                                width={25}
                                                                height={25}
                                                                bgColor='#10B981'
                                                                customClass='top-2'
                                                            />
                                                            <span className='ms-1'>Admin Review</span>
                                                        </p>
                                                    )

                                                }

                                                // rejected
                                                if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REJECTED && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN) {
                                                    return (
                                                        <p className='m-0 text-nowrap '
                                                            key={inx + 'admin'}
                                                        >
                                                            <IconRejectStep
                                                                customClass='top-2'
                                                                width={25}
                                                                height={25}
                                                            />
                                                            <span className='ms-1'>Admin Review</span>
                                                        </p>
                                                    )

                                                }

                                                // only number
                                                if (
                                                    parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REVIEW &&
                                                    parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.AGENT
                                                ) {
                                                    return (
                                                        <p
                                                            key={inx + 'admin'}
                                                            className='m-0 text-nowrap '>
                                                            <span className='default-step me-1 float-start'>02</span>
                                                            <span className='ms-1'>Admin Review</span>
                                                        </p>
                                                    )
                                                }
                                            })
                                        }

                                    </div>
                                    <div className='col-md-4'>

                                        {
                                            currentItem && currentItem.item_approval.map((iap, inx) => {
                                                // default orange
                                                if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REVIEW && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.SUPER_ADMIN) {
                                                    return (
                                                        <p className='m-0 text-nowrap '
                                                            key={inx + 'super-admin'}
                                                        >
                                                            <IconCurrentStep
                                                                width={25}
                                                                height={25}
                                                                customClass='top-2'
                                                            />
                                                            <span className='ms-1'>Super Admin Review -</span>
                                                        </p>
                                                    )

                                                }

                                                //approved
                                                if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.APPROVED && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.SUPER_ADMIN) {
                                                    return (
                                                        <p className='m-0 text-nowrap '
                                                            key={inx + 'super-admin'}
                                                        >
                                                            <IconCurrentStep
                                                                width={25}
                                                                height={25}
                                                                bgColor='#10B981'
                                                                customClass='top-2'
                                                            />
                                                            <span className='ms-1'>Super Admin Review -</span>
                                                        </p>
                                                    )

                                                }

                                                // rejected
                                                if (parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REJECTED && parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.SUPER_ADMIN) {
                                                    return (
                                                        <p className='m-0 text-nowrap '
                                                            key={inx + 'super-admin'}
                                                        >
                                                            <IconRejectStep

                                                                width={25}
                                                                height={25}
                                                                customClass='top-2'
                                                            />
                                                            <span className='ms-1'>Super Admin Review -</span>
                                                        </p>
                                                    )

                                                }

                                                // only number
                                                if (
                                                    parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REVIEW &&
                                                    parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN
                                                ) {
                                                    return (
                                                        <p
                                                            key={inx + 'super-admin'}
                                                            className='m-0 text-nowrap '>
                                                            <span className='default-step me-1 float-start'>03</span>
                                                            <span className='ms-1'>Super Admin Review</span>
                                                        </p>
                                                    )
                                                }
                                                // only number
                                                if (
                                                    parseInt(iap.approval_status) === ORDER_ITEM_AR_STATUS.REVIEW &&
                                                    parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.AGENT
                                                ) {
                                                    return (
                                                        <p
                                                            key={inx + 'super-admin'}
                                                            className='m-0 text-nowrap '>
                                                            <span className='default-step me-1 float-start'>03</span>
                                                            <span className='ms-1'>Super Admin Review</span>
                                                        </p>
                                                    )
                                                }
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* Stepper end */}

                        <div className="col-md-12 mt-5">
                            <h5><strong>Item Details</strong></h5>
                            {currentItem && currentItem && (
                                <ItemDetails
                                    cc={currentItem.customer_contribution}
                                    mc={currentItem.mc_contribution}
                                    items={[currentItem]}
                                />)}
                        </div>
                        <div className="col-md-12 mt-5">
                            <h5><strong>Uploaded Documents</strong></h5>
                            <div className='row'>
                                {/* customer photo */}
                                <div className='col-2'>
                                    <div className='form-group'>
                                        <label className='w-100'>Customer photo</label>
                                        {customer_photo ? (
                                            customer_photo.file.file_type === 'application/pdf' ? ((<><iframe src={`data:${customer_photo.file.file_type};base64,${customer_photo.base64}`} className='w-100 h-100' /></>)) : (<><img className='w-100 h-100' src={`data:${customer_photo.file.file_type};base64,${customer_photo.base64}`} /></>)
                                        ) : (
                                            <>
                                                {userData && userData.user_role_id === DEFAULT_USER_ROLE.AGENT && (
                                                    <div className="upload-btn-wrapper ">
                                                        <button className="upload-btn-bdr btn ">Upload a file</button>
                                                        <input type="file" name="customer_photo" onChange={uploadFile} />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* metal photo */}
                                <div className='col-2'>
                                    <div className='form-group'>
                                        <label className='w-100'>Metal photo</label>
                                        {metal_photo ? (
                                            metal_photo.file.file_type === 'application/pdf' ? ((<><iframe src={`data:${metal_photo.file.file_type};base64,${metal_photo.base64}`} className='w-100 h-100' /></>)) : (<><img className='w-100 h-100' src={`data:${metal_photo.file.file_type};base64,${metal_photo.base64}`} /></>)
                                        ) : (

                                            <>
                                                {userData && userData.user_role_id === DEFAULT_USER_ROLE.AGENT && (
                                                     <div className="upload-btn-wrapper ">
                                                     <button className="upload-btn-bdr btn ">Upload a file</button>
                                                     <input type="file" name="metal_photo" onChange={uploadFile} />
                                                 </div>
                                                )}
                                            </>
                                          
                                        )}
                                    </div>
                                </div>
                                {/* weight photo */}
                                <div className='col-2'>
                                    <div className='form-group'>
                                        <label className='w-100'>Weight photo</label>

                                        {weight_photo ? (
                                            weight_photo.file.file_type === 'application/pdf' ? ((<><iframe src={`data:${weight_photo.file.file_type};base64,${weight_photo.base64}`} className='w-100 h-100' /></>)) : (<><img className='w-100 h-100' src={`data:${weight_photo.file.file_type};base64,${weight_photo.base64}`} /></>)
                                        ) : (

                                            <>
                                                {userData && userData.user_role_id === DEFAULT_USER_ROLE.AGENT && (
                                                    <div className="upload-btn-wrapper ">
                                                    <button className="upload-btn-bdr btn ">Upload a file</button>
                                                    <input type="file" name="weight_photo" onChange={uploadFile} />
                                                </div>
                                                )}
                                            </>
                                           
                                        )}
                                    </div>
                                </div>
                                {/* purity photo */}
                                <div className='col-2'>
                                    <div className='form-group'>
                                        <label className='w-100'>Purity photo</label>
                                        {purity_photo ? (
                                            purity_photo.file.file_type === 'application/pdf' ? ((<><iframe src={`data:${purity_photo.file.file_type};base64,${purity_photo.base64}`} className='w-100 h-100' /></>)) : (<><img className='w-100 h-100' src={`data:${purity_photo.file.file_type};base64,${purity_photo.base64}`} /></>)
                                        ) : (

                                            <>
                                                {userData && userData.user_role_id === DEFAULT_USER_ROLE.AGENT && (
                                                   <div className="upload-btn-wrapper ">
                                                   <button className="upload-btn-bdr btn ">Upload a file</button>
                                                   <input type="file" name="purity_photo" onChange={uploadFile} />
                                               </div>
                                                )}
                                            </>
                                           
                                        )}
                                    </div>
                                </div>
                                <div className='clearfix'></div>
                                {/* support document */}
                                <div className='col-12 mt-3'>
                                    <div className='form-group'>
                                        <label className='w-100'>Support document</label>
                                        <div className='row'>
                                            <MultipleFilePreview files={supportingDocuments}
                                            />
                                        </div>
                                        <div className="upload-btn-wrapper ">
                                            <button className="upload-btn-bdr btn ">Upload a file</button>
                                            <input type="file" name={ATTACHMENT_TYPE.ORDER_ITEM_SUPPORT_DOCUMENT} multiple onChange={uploadFile} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 className='mt-5 mb-3'><strong>Comments</strong></h5>
                            <div className='row'>
                                {currentItem && currentItem.comments.map((comment, cmdInx) => (
                                    <div className='col-12' key={cmdInx}>
                                        <p className='m-0'><strong>{comment.first_name} {comment.middle_name} {comment.last_name} ({comment.user_role_name})</strong></p>
                                        <p className='m-0'>{comment.message}</p>
                                        <p className='m-0'>{displayOrderCreated(comment.created_at)}</p>
                                        <hr />
                                    </div>
                                ))}
                            </div>

                        </div>
                        {showARButton && (
                            <div className='col-12 text-end' key={'tmd'}>
                                <button
                                    className="btn btn-approve-primary me-3"
                                    type='button'
                                    onClick={(e) => itemStatus(ORDER_ITEM_AR_STATUS.APPROVED)}
                                >
                                    Approve
                                </button>
                                <button
                                    className="btn btn-reject-primary"
                                    type='button'
                                    onClick={(e) => itemStatus(ORDER_ITEM_AR_STATUS.REJECTED)}
                                >
                                    Reject
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                header="Comments"
                visible={showComment}
                style={{ width: '25vw' }}
                onHide={e => dispatch(setShowComment(false))}
                // maximizable
                className='comments-box'
            >
                <div className='form-group'>
                    <label>Status: {actionStatus && actionStatus === ORDER_ITEM_AR_STATUS.APPROVED ? "Approve" : "Reject"}</label>
                </div>
                <div className='form-group'>
                    <label>Message</label>
                    <textarea
                        onChange={handleChangeComment}
                        name='comments'
                        rows={4}
                        className='form-control'
                        value={comments}
                    >
                        {comments}
                    </textarea>
                    {errors.comments && <span className="error-message">{errors.comments}</span>}
                </div>
                <div className='form-group mt-4 text-end mb-3'>
                    <button
                        disabled={disabledButton}
                        className="btn  btn-purple-primary"
                        type='button'
                        onClick={updateOrderItemStatus}
                    >
                        {disabledButton ? <Spinner /> : null} Submit
                    </button>
                </div>
            </Dialog>
            <FilePreview />
        </div>
    )
}


export default TradingItemApproval;

