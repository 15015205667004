
// Import files and libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';



// others
import { IconPlus } from '../../../svg-icons';
import { ID_TYPE, ORDER_STATUS, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, validatePermission } from '../../../config/constant';
import TradingFormModel from './form-modal';
// import TradingDetails from './trade-details';


//reducer
import { setUpdateId, setFormModel, setDefaultFormData, setTradeDetails } from '../../../store/trade/reducer';
import { setProDocDefault, setTableLoader, updateLoaderStatus, updatePageTitle } from '../../../store/general/reducer';

//action
import { actionGetAll, actionGetById } from '../../../store/trade/action';

//custom component
import DataTableComponent from '../../../component/data-table';
import { getDisplayDateTimeFormater } from '../../../helper/date-helper';
import { actionGetOrganizationList } from '../../../store/organization/action';
import { useNavigate } from 'react-router-dom';
import GetOrderStatus from '../../../component/order-status';
import PermissionDenied from '../../../component/permission-denied';




const TradingPage = () => {

  // dispatch action
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get the table details like sort, order, page, search, page, page size, table data, total records
  const { search, formModel } = useSelector((state) => state.trade);
  const { sort, order, limit, offset, totalRecords } = useSelector((state) => state.table[TABLES.USER]);
  const { defaultUserPermission } = useSelector((state) => state.auth);

  // handle model show
  const showModel = async () => {
    // clear the form details
    dispatch(setDefaultFormData());
    // remove the update id
    dispatch(setUpdateId(null));

    // show the model
    dispatch(setFormModel(true));
  };
  useEffect(() => {
    //   dispatch(updatePageTitle('Trading'));
    //   dispatch(setProDocDefault());
    //   // remove the update id
    //   dispatch(setUpdateId(null));
    //   // show the model
    //   dispatch(setFormModel(false));
    dispatch(actionGetOrganizationList());
  }, []);

  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    // call the api function
    dispatch(setTableLoader(true));
    getAllData();
  }, [limit, offset, search, sort, order,defaultUserPermission]);


  // get data form the api
  const getAllData = async () => {
    // dispatch the data to the API call
    dispatch(actionGetAll())
  }


  const tableColumns = [

    {
      field: 'row_ref_number',
      header: 'TRADING ID'
    },
    {

      field: '',
      header: 'CUSTOMER NAME',
      body: (row) => {
        return `${row.customer_first_name} ${row.customer_middle_name} ${row.customer_last_name}`;
      }
    },
    {

      field: '',
      header: 'AGENT NAME',
      body: (row) => {
        return `${row.agent_first_name} ${row.agent_middle_name} ${row.agent_last_name}`;
      }
    },

    {

      field: '',
      header: 'NO OF TRADING ITEMS'
    },

    {
      sortable: false,
      field: 'status',
      header: 'STATUS',

      body: (row) => {
        return <GetOrderStatus status={row.order_status} />;
      }
    },
    {
      sortable: false,
      field: 'created_at',
      header: 'DATE',
      headerAlign: 'text-end',
      bodyAlign: 'text-end',
      body: (row) => {
        return getDisplayDateTimeFormater(row.created_at)
      }
    }

  ];
  const selectedRow = (e) => {
    dispatch(setTradeDetails(null));
    navigate("/portal/trading/details?type=order&view=" + e.data.slug);
  }


  // page design
  return (
    <>
    {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.READ) ? (
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            {formModel ? (
              <div className="row">
                <div className='col-12'>
                  <TradingFormModel />
                </div>
              </div>
            ) : (

              <div className="row">
                <div className='col-6'>
                  <h3 className='page-title'>Trading</h3>
                  <p className='sub-page-title table-total-count'>Total number of Tradings - {totalRecords}</p>
                </div>
                {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.ADD) && (
                <div className='col-6 text-end'>
                  <button className='btn btn-orange-primary' onClick={showModel}>
                    <IconPlus customClass='text-white' /> Create Trade
                  </button>
                </div>
                )}
                <div className='col-12'>
                  <DataTableComponent
                    tableKey={TABLES.USER}
                    columns={tableColumns}
                    onTableRowSelect={selectedRow}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
       ) : defaultUserPermission && <PermissionDenied />}
    </>
  );

}

export default TradingPage;
