import React from 'react';

const CustomerTopMenu = () => {

  
  return (
    <>
    Customer top menu bar
    </>
  );
};

export default CustomerTopMenu;
