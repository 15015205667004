// import moment from 'moment-timezone';
import moment from 'moment';

// moment(new Date()).tz(moment.tz.guess());

export const getDisplayDateTimeFormater = (date) => {
    return moment(date).format('DD-MM-YYYY HH:mm');
};
export const getDateYearMonth = (date) => {
    return moment(date).format('YYYYMM');
};

export const getDisplayDateOnly = (date) => {
    return moment(date).format('DD-MM-YYYY');
};
export const getDefaultDateFormatOnly = (date) => {
    return moment(date)
    .format('YYYY-MM-DD');
};
export const getDateISO = (date) => {
    return moment(date).toISOString();
};

export const getDateOnlyByDisplay =  (date) => {
    
    return moment(date).format('DD/MM/YYYY');
};
export const databaseOrderCreated = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
};
export const databaseCreateDateOnly = (date) => {
    return moment(date).format('YYYY-MM-DD');
};
export const databaseCreateTimeOnly = (date) => {
    return moment(date).format('HH:mm:ss');
};
export const displayOrderCreated = (date) => {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
};
export const getDateFormatorForCommants = (date) => {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('MMMM DD, YYYY hh:mm A');
};

export const getDisplayTimeFormater =  (date) => {
    return moment(date, 'HH:mm:ss').format('hh:mm:ss');
};
export const getDisplayTime =  (date) => {
    return moment(date).format('HH:mm');
};
export const getDateTimeFromTime =  (time) => {
    return moment(time,'HH:mm:ss');
};
