
// Import files and libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


// others
import { IconPlus } from '../../../svg-icons';
import { DEFAULT_USER_ROLE, PERMISSION_KEY, PERMISSION_PAGE_KEY, RECORD_STATUS, TABLES } from '../../../config/constant';
import { getDisplayDateTimeFormater } from '../../../helper/date-helper';
import CustomerFormModel from './form-modal';


//reducer
import { setUpdateId, setFormModel, setDefaultFormData } from '../../../store/customer/reducer';
import { setProDocDefault, setTableLoader, updateLoaderStatus, updatePageTitle } from '../../../store/general/reducer';

//action
import { actionGetAll, actionGetById } from '../../../store/customer/action';

//custom component
import DataTableComponent from '../../../component/data-table';
import { getDisplayIdByRole } from '../../../helper/common-helper';
import { actionGetLocationList } from '../../../store/location/action';
import PermissionDenied from '../../../component/permission-denied';
import { actionGetOrganizationList } from '../../../store/organization/action';



const CustomerPage = () => {

  // dispatch action
  const dispatch = useDispatch();

  // get the table details like sort, order, page, search, page, page size, table data, total records
  const { search, formModel } = useSelector((state) => state.customer);
  const { defaultUserPermission } = useSelector((state) => state.auth);
  const { sort, order, limit, offset, totalRecords } = useSelector((state) => state.table[TABLES.USER]);

  // handle model show
  const showModel = async () => {
    // clear the form details
    dispatch(setDefaultFormData());
    dispatch(setProDocDefault());
    // remove the update id
    dispatch(setUpdateId(null));

    // show the model
    dispatch(setFormModel(true));
  };

  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    dispatch(updatePageTitle('Customer'));
    // call the api function
    dispatch(setTableLoader(true));
    getAllData();
  }, [limit, offset, search, sort, order, defaultUserPermission]);

  useEffect(() => {
    dispatch(setProDocDefault());
    // remove the update id
    dispatch(setUpdateId(null));
    // show the model
    dispatch(setFormModel(false));
    getLocations();
    getOrganizations();
  }, []);

  
  const getOrganizations = () => {
    dispatch(actionGetOrganizationList());
  }

  const getLocations = () => {
    dispatch(actionGetLocationList());
  }


  // get data form the api
  const getAllData = async () => {
    // dispatch the data to the API call
    dispatch(actionGetAll())
  }


  //get the specific details by id
  const getById = async (id) => {
    // dispatch the action
    dispatch(updateLoaderStatus(true));
    dispatch(setProDocDefault());
    dispatch(actionGetById(id));
  }



  const tableColumns = [

    {

      field: 'row_ref_number',
      header: 'ID'
    },
    {

      field: 'first_name',
      header: 'NAME'
    },

    {

      field: 'email',
      header: 'E-MAIL'
    },

    {
      sortable: false,
      field: 'phone_number',
      header: 'PHONE NUMBER',
      headerAlign: 'text-end',
      bodyAlign: 'text-end'
    },
    {
      field: 'location.location_name',
      header: 'LOCATION'
    },

    {
      sortable: false,
      field: 'status',
      header: 'STATUS',
      headerAlign: 'text-center',
      bodyAlign: 'text-center',
      body: (row) => {
        return (<>
          {row.active === RECORD_STATUS.ACTIVE.id ? (
            <span className='badge badge-active w-100'>{RECORD_STATUS.ACTIVE.name}</span>
          ) : (
            <span className='badge badge-inactive w-100'>{RECORD_STATUS.INACTIVE.name}</span>
          )}
        </>)
      }
    }
  ];

  const selectedRow = (e) => {
    getById(e.data.id);
  }
  // page design
  return (
    <>
     {defaultUserPermission && ((defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] && defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][PERMISSION_KEY.READ]) || defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] && defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][PERMISSION_KEY.ADD_ONLY]) ? (
      <div className='container-fluid'>
        <div className="card mb-3">
          <div className="card-body">
            {formModel || defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] && defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][PERMISSION_KEY.ADD_ONLY]? (
              <div className="row">
                <div className='col-12'>
                  <CustomerFormModel />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className='col-6'>
                  <h3 className='page-title'>Customer List</h3>
                  <p className='sub-page-title table-total-count'>Total number of Customers - {totalRecords}</p>
                </div>
                {defaultUserPermission && defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER] && defaultUserPermission[PERMISSION_PAGE_KEY.CUSTOMER][PERMISSION_KEY.ADD] && (
                <div className='col-6 text-end'>
                  <button className='btn btn-orange-primary' onClick={showModel}>
                    <IconPlus customClass='text-white' /> Create Customer
                  </button>
                </div>
                 )}
                <div className='col-12'>
                  <DataTableComponent
                    tableKey={TABLES.USER}
                    columns={tableColumns}
                    onTableRowSelect={selectedRow}
                  />
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
      ) :  defaultUserPermission && <PermissionDenied />}
    </>
  );

}

export default CustomerPage;
