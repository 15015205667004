import React, { useRef, useEffect } from 'react';
import GeneralRoutes from "./layouts/route-navigation/general-routes";
import PortalRoutes from "./layouts/route-navigation/portal-routes";
import CustomerRoutes from "./layouts/route-navigation/customer-routes";
import SideBar from "./layouts/sidebar";
import GeneralTopMenu from "./layouts/general-top-menu";
import CustomerHeader from "./layouts/customer-header";

import { setNetworkOffline, setDeviceWidth } from "./store/general/reducer";
import Loader from "./component/loader";
import { useSelector, useDispatch } from 'react-redux';

import ToastProvider from "./helper/toast-provider-helper";
import { getDefaultUserPermission } from './store/auth/actions';
import { DEFAULT_USER_ROLE, ENVIRONMENT } from './config/constant';

function App() {

  const elementRef = useRef(null);

  const { isAuth, userData } = useSelector(state => state.auth);
  const { networkOffline, pageTitle } = useSelector(state => state.general);

  const dispatch = useDispatch();

  window.addEventListener('offline', function (e) {
    dispatch(setNetworkOffline(true));
  });

  window.addEventListener('online', function (e) {
    dispatch(setNetworkOffline(false));
  });

  useEffect(() => {
    if (elementRef.current) {
      dispatch(setDeviceWidth(elementRef.current.offsetWidth));
    }
    let fontSizes = "";
    for(let i=8; i < 36; i++){
      fontSizes +=` .fnt-size-${i}{
      font-size:${i}px;
      }
      `;
    }
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .bg-image-one {
        background-image: url(${ENVIRONMENT.BASE_URL}assets/images/img-01.jpg);
      }
      .bg-image-two {
        background-image: url(${ENVIRONMENT.BASE_URL}assets/images/img-02.png);
      }
      .bg-customer-register {
        background-image: url(${ENVIRONMENT.BASE_URL}assets/images/register-bg.png);
      }
        ${fontSizes}
    `;
    document.head.appendChild(styleElement);
  }, []);

  useEffect(() => {
    isAuth === true && getUserPermission();
  }, [isAuth]);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  const getUserPermission = () => {
    dispatch(getDefaultUserPermission());
  }

  return (
    <>
      <ToastProvider>
        <Loader />
        {networkOffline ? (<div className="alert alert-danger m-0 text-center" role="alert">No internet connection </div>) : null}
        {/* general public routes */}
        {!isAuth && (
          <>
            <GeneralTopMenu />
            <GeneralRoutes />
          </>
        )}
        {isAuth && userData.user_role_id === DEFAULT_USER_ROLE.CUSTOMER && (
          <>
            <CustomerHeader />
            <CustomerRoutes />
          </>
        )}
        {isAuth && userData.user_role_id !== DEFAULT_USER_ROLE.CUSTOMER && (
          <div className="wrapper" ref={elementRef}>
            <SideBar />
            <div id="content" className='pt-3'>
              <PortalRoutes />
            </div>
          </div>
        )}
      </ToastProvider>
    </>
  );
}

export default App;
