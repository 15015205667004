// impor the libraries
import { createSlice } from '@reduxjs/toolkit'

import { fixedTwoDigit, roundUpValue } from '../../helper/common-helper';
import { act } from 'react';

const defaultFormData = {
  customer_contribution: 10,
  mc_contribution: 15,
  customer_id: null,
  agent_id: null,
  location_id: null,
  organization_id: null,
  scheduled_date: "",
  scheduled_time: "",
  productTotal: 0,
  certificationCharges: 0,
};

let itemDetails = {
  loyalty: 0,
  per_gram_price: 0,
  certification_need: 0,
  certification_charges: 0,
  product_name: '',
  price: 0,
  purity: 0,
  weight: 0,
  product_id: '',
  customer_photo: null,
  metal_photo: null,
  weight_photo: null,
  purity_photo: null,
  supporting_documents: {},
  product_attributes: []
}

// user default initial state
const initialState = {
  formData: defaultFormData,
  formModel: false,
  updateId: false,
  selectedCustomer: null,
  productItems: [{ ...itemDetails }],
  tradeDetails: null,
  currentItem: null,
  showComment: false,
  comments: ''
}

// create a slice and reducers
const tradeSlice = createSlice({
  name: 'trade',
  initialState,
  reducers: {
    setFormModel: (state, action) => {
      state.formModel = action.payload;
    },
    setShowComment: (state, action) => {
      state.showComment = action.payload;
    },
    setComment: (state, action) => {
      state.comments = action.payload;
    },

    setUpdateId: (state, action) => {
      state.updateId = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDefaultFormData: (state, action) => {
      state.formData = defaultFormData;
      state.selectedCustomer = null;
      state.productItems = [itemDetails];
    },

    setSelectedCustomer: (state, action) => {
      const customer = action.payload;
      if (customer) {
        state.formData.agent_id = customer.agent_id;
        state.formData.organization_id = customer.organization_id;
      } else {
        state.formData.agent_id = "";
        state.formData.organization_id = "";
      }
      state.selectedCustomer = customer;
    },

    setAddProductItems: (state, action) => {
      state.productItems = [
        ...state.productItems,
        itemDetails
      ];
    },

    setRemoveItem: (state, action) => {
      state.productItems = action.payload;
    },
    setItemUpdate: (state, action) => {
      let { inx, metalItem } = action.payload;

      // chcek per gram price
      if (parseInt(metalItem.product_id) === 1) {
        // gold
        metalItem.price = metalItem.purity === 24 ? metalItem.per_gram_price : (metalItem.purity / 24) * metalItem.per_gram_price;
      } else if (parseInt(metalItem.product_id) === 2 || parseInt(metalItem.product_id) === 3) {
        // sliver &&  platinum
        metalItem.price = metalItem.purity === 999 ? metalItem.per_gram_price : (metalItem.purity / 999) * metalItem.per_gram_price;
      }

      state.productItems[inx] = metalItem;
    },
    setItemAttributes: (state, action) => {
      const { inx, attributes } = action.payload;
      state.productItems[inx].purity = attributes[0].attribute_value;
      state.productItems[inx].product_attributes = attributes;

      let metalItem = { ...state.productItems[inx] };
      // chcek per gram price
      if (parseInt(metalItem.product_id) === 1) {
        // gold
        metalItem.price = metalItem.purity === 24 ? metalItem.per_gram_price : (metalItem.purity / 24) * metalItem.per_gram_price;
      } else if (parseInt(metalItem.product_id) === 2 || parseInt(metalItem.product_id) === 3) {
        // sliver &&  platinum
        metalItem.price = metalItem.purity === 999 ? metalItem.per_gram_price : (metalItem.purity / 999) * metalItem.per_gram_price;
      }
      state.productItems[inx] = metalItem;
    },

    setItemFileData: (state, action) => {
      console.log(action.payload)
      const { inx, name, data } = action.payload;
      // Replace the placeholder with the uploaded file data
      state.productItems[inx][name] = data;
    },
    setItemSupportingDocumentPlaceholder: (state, action) => {
      const { inx, file, itemInx } = action.payload;
      console.log(action.payload)
      state.productItems[itemInx].supporting_documents[inx] = { placeholder: true, uploadProgress: 0, file };
    },

    setItemSupportingDocumentProgress: (state, action) => {
      const { inx, percentCompleted, itemInx } = action.payload;
      console.log(action.payload, state.productItems[itemInx].supporting_documents[inx]);
      state.productItems[itemInx].supporting_documents[inx] = {
        ...state.productItems[itemInx].supporting_documents[inx],
        uploadProgress: percentCompleted
      }
    },
    setItemSupportingDocumentData: (state, action) => {
      const { data, inx, itemInx } = action.payload;
      state.productItems[itemInx].supporting_documents[inx] = {
        ...data
      };
    },
    setTradeDetails: (state, action) => {
      state.tradeDetails = action.payload;
    },
    setCurrentItem: (state, action) => {
      state.currentItem = action.payload;
    },
    setClearTradeState: (state, action) => {
      state.formData = defaultFormData;
      state.formModel = false;
      state.updateId = null;
      state.selectedCustomer = null;
      state.productItems = [{ ...itemDetails }];
      state.tradeDetails = null;
      state.currentItem = null;
      state.showComment = false;
      state.comments = '';

    }
  }
})
// export the user module
export default tradeSlice.reducer;

export const {
  setFormModel,
  setShowComment,
  setComment,
  setUpdateId,
  setFormData,
  setDefaultFormData,
  setSelectedCustomer,
  setAddProductItems,
  setRemoveItem,
  setItemUpdate,
  setItemAttributes,
  setItemFileData,
  setItemSupportingDocumentPlaceholder,
  setItemSupportingDocumentProgress,
  setItemSupportingDocumentData,
  setTradeDetails,
  setCurrentItem,
  setClearTradeState
} = tradeSlice.actions;

