import React from 'react';
import { currencyFormatValue, fixedTwoDigit, roundUpValue } from '../helper/common-helper';

const ItemDetails = ({ items = [], cc = 0, mc = 0 }) => {

    let totalProductCost = 0, productItems = [], certificationCharges = 0;

    items.forEach(item => {
        certificationCharges += item.certification_charges;
        totalProductCost += (
            fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
            -
            (
                fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
                *
                fixedTwoDigit(item.loyalty / 100)
            )
        );
        productItems.push(item);
    });

    return (
        <table className='table'>
            <thead>
                <tr>
                    <th className='text-center'>Item</th>
                    <th className='text-center'>Weight</th>
                    <th className='text-center'>Carat</th>
                    <th className='text-end'>Product Price</th>
                    <th className='text-center'>Loyalty %</th>
                    <th className='text-center'>Value after Loyalty</th>
                </tr>
            </thead>
            <tbody>
                {productItems.length > 0 && productItems.map((item, inx) => (
                    <tr key={inx + "tr"}>
                        <td className='text-center'>{item.product_name?item.product_name:item.sub_category_name}</td>
                        <td className='text-center'>{fixedTwoDigit(parseFloat(item.weight))} GM</td>
                        <td className='text-center'>{item.purity}kt</td>
                        <td className='text-end'>{
                            currencyFormatValue(
                                fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight))),false
                            )
                        } KSH</td>
                        <td className='text-center'>{item.loyalty}%</td>
                        <td className='text-end'>
                            {
                                currencyFormatValue(
                                    fixedTwoDigit(
                                        fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
                                        -
                                        (
                                            fixedTwoDigit(fixedTwoDigit(item.price) * fixedTwoDigit(parseFloat(item.weight)))
                                            *
                                            fixedTwoDigit(item.loyalty / 100)
                                        )
                                    )
                                )
                            } KSH<span className='ms-3'>&nbsp;</span>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={4} className='text-end'>&nbsp;</td>
                    <td>Product Total</td>
                    <td className='text-end'>{
                        currencyFormatValue(totalProductCost)
                    } KSH<span className='ms-3'>&nbsp;</span></td>
                </tr>
                <tr>
                    <td colSpan={4} className='text-end'>&nbsp;</td>
                    <td>Certification charges</td>
                    <td className='text-end'>{
                        currencyFormatValue(
                            roundUpValue(certificationCharges)
                        )
                    } KSH<span className='ms-1'>(-)</span></td>
                </tr>
                <tr>
                    <td colSpan={4} className='text-end'>&nbsp;</td>
                    <td>Customer Contribution ({cc}%)</td>
                    <td className='text-end'>{
                        currencyFormatValue(
                            roundUpValue(
                                (cc / 100)
                                *
                                totalProductCost
                            )
                        )
                    } KSH<span className='ms-1'>(-)</span>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4} className='text-end'>&nbsp;</td>
                    <td>Madini Chapaa Contribution (15%)</td>
                    <td className='text-end'>{
                        currencyFormatValue(
                            roundUpValue(
                                (mc / 100)
                                *
                                totalProductCost
                            )
                        )
                    } KSH<span className='ms-1'>(+)</span>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4} className='text-end'>&nbsp;</td>
                    <td>Total Disbursal Amount</td>
                    <td className='text-end'>
                        {
                            currencyFormatValue(
                                roundUpValue(
                                    totalProductCost
                                    -
                                    roundUpValue(certificationCharges)
                                    -
                                    roundUpValue(
                                        (cc / 100)
                                        *
                                        totalProductCost
                                    )
                                )
                            )
                        } KSH<span className='ms-3'>&nbsp;</span>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4} className='text-end'>&nbsp;</td>
                    <td>Total Wallet Balance</td>
                    <td className='text-end'>{
                        (
                            currencyFormatValue(
                                roundUpValue(
                                    (cc / 100)
                                    *
                                    totalProductCost
                                )
                                +
                                roundUpValue(
                                    (mc / 100)
                                    *
                                    totalProductCost
                                )
                            )
                        )
                    } KSH<span className='ms-3'>&nbsp;</span>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default ItemDetails;
