// import io from 'socket.io-client';
import { DEFAULT_USER_ROLE, HTTP_STATUS_CODE, ID_TYPE, ORDER_STATUS, TOAST_TYPE } from '../config/constant';
import { store } from '../store/store';
import { getDateYearMonth } from './date-helper';
import { showToast } from './toast-service-helper';


// axios header configuration with user token
export const axiosOptions = async () => {

    // get the user token
    const token = await store.getState().auth.userToken;

    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }
}
// axios header configuration with user token & formData
export const axiosOptionsForFormData = async () => {

    // get the user token
    const token = await store.getState().auth.userToken;

    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    }
}
export const getFileExtension = (fileName) => {
    return (fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)).toUpperCase();
}

// Deep copy
export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Byte';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const truncateFileName = (fileName, maxLength = 10) => {
    const parts = fileName.split('.');
    if (parts.length < 2) return fileName; // No extension found

    const extension = parts.pop(); // Get the extension
    const baseName = parts.join('.'); // Get the base name without extension

    if (baseName.length > maxLength) {
        const truncatedBaseName = baseName.substring(0, maxLength - 3) + '...'; // Truncate and add ellipsis
        return `${truncatedBaseName}.${extension}`;
    }

    return `${baseName}.${extension}`;
};


export const validateFileSize = (file, maxSizeMB = 5) => {
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > maxSizeMB) {
        // throw new Error(ERROR_MESSAGE.FILE_SIZE);
    }
};

export const fixedTwoDigit = (value) => {
    return typeof value === 'string' ? parseFloat(value).toFixed(2) : value.toFixed(2);
}
export const roundUpValue = (value) => {
    return Math.round(value);
}

export const getDisplayIdByRole = (createdAt, userRole, id) => {
    const ID = id < 10 ? `0${id}` : id;
    switch (userRole) {
        case DEFAULT_USER_ROLE.SUPER_ADMIN:
        case DEFAULT_USER_ROLE.ADMIN:
            return `M${getDateYearMonth(createdAt)}-${ID}`;
        case DEFAULT_USER_ROLE.AGENT:
            return `A${getDateYearMonth(createdAt)}-${ID}`;
        case DEFAULT_USER_ROLE.ORGANIZATION:
            return `O${getDateYearMonth(createdAt)}-${ID}`;
        case DEFAULT_USER_ROLE.CUSTOMER:
            return `C${getDateYearMonth(createdAt)}-${ID}`;
    }
}
export const getDisplayIdByType = (createdAt, type, id) => {
    const ID = id < 10 ? `0${id}` : id;
    switch (type) {
        case ID_TYPE.PRODUCT:
            return `P${getDateYearMonth(createdAt)}-${ID}`;
        case ID_TYPE.LOCATION:
            return `L${getDateYearMonth(createdAt)}-${ID}`;
        case ID_TYPE.TRADING:
            return `T${getDateYearMonth(createdAt)}-${ID}`;
        case ID_TYPE.TRADING_ITEM:
            return `IT${getDateYearMonth(createdAt)}-${ID}`;
    }
}


//remove .00
export const validateFloatNumber = (amount) => {
    if (typeof amount === 'string' || typeof amount === 'number') {
        var num = parseFloat(amount);

        // Check if the number is an integer (i.e., no decimal part or .00)
        if (Number.isInteger(num)) {
            // If it's an integer, return the number without decimal part
            return num;
        } else {
            // If it's not an integer, return the original amount
            return amount;
        }
    } else {
        return amount;
    }
}

// indian currency format
export const currencyFormatValue = (amount, roundUp = true) => {
    if (roundUp) {
        amount = Math.round(amount);
    }
    return new Intl.NumberFormat('en-KE', {
        // style: 'currency',
        // currency: 'KSH',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
};


export const handingApiErrors = (error) => {
    if (error.response.status === HTTP_STATUS_CODE.VALIDATION_ERROR) {
        if (typeof error.response.data.message === "string") {
            showToast(TOAST_TYPE.ERROR, error.response.data.message, 'top-right');
            return;
        }
        Object.values(error.response.data.message)
            .map((e, index) => {
                showToast(TOAST_TYPE.ERROR, e, 'top-right');
            });
    }
}