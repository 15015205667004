//reducer
import {
    setComment,
    setCurrentItem,
    setFormModel,
    setItemAttributes,
    setItemFileData,
    setItemSupportingDocumentData,
    setSelectedCustomer,
    setShowComment,
    setTradeDetails,
    setUpdateId,
    setViewCustomer
} from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton,  setFilePreview, setFilePreviewModel, setFileData, setBulkSupportingDocumentData } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import tradeService from '../../services/trade';
import { actionGetFileById } from "../general/action";
import customerService from '../../services/customer';
import paymentService from '../../services/payment';
import subCategoryAttributeService from '../../services/sub-category-attribute';
import fileService from '../../services/file';
import { formatFileSize, truncateFileName } from "../../helper/common-helper";
import { actionGetAgentList } from "../agent/action";

// export user create action
export const actionCreate = (data) => {
    return async (dispatch, getState) => {
        const response = await tradeService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.CREATE) {
            await dispatch(actionGetAll());
            await dispatch(setFormModel(false));
            
            showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');
        }
    }
}


// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {

        const {defaultUserPermission} = getState().auth;
        if(!validatePermission(defaultUserPermission,PERMISSION_PAGE_KEY.TRADING,PERMISSION_KEY.READ)){
            return;
        }

        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset,
            limit: getParams.limit
        };
        const response = await tradeService.getAll(data);
        // dispatch(updateLoaderStatus(false));
        dispatch(setTableLoader(false));
        if (response?.status === HTTP_STATUS_CODE.GET) {
            await dispatch(setTableData({
                data: response.data.data.orders,
                totalRecord: response.data.data.total_count,
                tableKey: TABLES.USER
            }));
        }
    }
}

export const actionGetById = (id) => {
    return async (dispatch, getState) => {
        const response = await tradeService.getById(id);
        dispatch(updateLoaderStatus(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            const data = response.data.data.order;
            await dispatch(setUpdateId(data.id));   
            await dispatch(setTradeDetails(data));
        }
    }
}
export const actionGetOrderItemById = (slug) => {
    return async (dispatch, getState) => {
        const response = await tradeService.getOrderItemBySlug(slug);
        dispatch(updateLoaderStatus(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            const data = response.data.data.order_item_details;
            await dispatch(setUpdateId(data.id));   
            await dispatch(setCurrentItem(data));
            if (data.customer_photo) {
                await dispatch(actionGetFileById(data.customer_photo.id, 'customer_photo'));
            }else{
                await dispatch(setFileData({name:'customer_photo',value:null}));
                
            }
            if (data.metal_photo) {
                await dispatch(actionGetFileById(data.metal_photo.id, 'metal_photo'));
            }else{
                await dispatch(setFileData({name:'metal_photo',value:null}));
                
            }
            if (data.weight_photo) {
                await dispatch(actionGetFileById(data.weight_photo.id, 'weight_photo'));
            }else{
                await dispatch(setFileData({name:'weight_photo',value:null}));
                
            }
            if (data.purity_photo) {
                await dispatch(actionGetFileById(data.purity_photo.id, 'purity_photo'));
            }else{
                await dispatch(setFileData({name:'purity_photo',value:null}));
                
            }

            let supportingDocuments = {};
            if (data?.supporting_documents && (data.supporting_documents).length > 0) {
                data.supporting_documents.forEach(file => {
                    
                    let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;

                    let fileDetails = {
                        ...file,
                        size: formatFileSize(file.file_size), 
                        name:truncateFileName(file.original_name)                       
                    };

                    supportingDocuments[inx] = fileDetails;
                });
                
                dispatch(setBulkSupportingDocumentData(supportingDocuments));               

            }
        }
    }
}

export const actionSearchCustomer = (search) => {
    
    return async (dispatch, getState) => {
        const response = await customerService.searchCustomer(search);
        if (response?.status === HTTP_STATUS_CODE.OK) {
            const data = response.data.data.customer;
            if(data){
                await dispatch(actionGetAgentList(data.organization_id))
            }
            await dispatch(setSelectedCustomer(data?data:null));
            if (response.data.data?.customer && data.profile) {
                await dispatch(actionGetFileById(data.profile.id, 'profile'));
            } else {
                await dispatch(setFileData({name:'profile',value:null}));

            }
        }
        dispatch(updateLoaderStatus(false));
    }
}

export const actionGetSubCatAttrWithInx = (subCatId, inx) => {
    return async (dispatch, getState) => {
        const response = await subCategoryAttributeService.list(subCatId);
        if (response?.status === HTTP_STATUS_CODE.OK) {
            await dispatch(setItemAttributes({ inx, attributes: response.data.data.sub_category_attributes }));
        }
    }
}


export const actionSingleUpload = (data, inx, name) => {
    return async (dispatch, getState) => {
        const response = await fileService.upload(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            await dispatch(setItemFileData({
                data: response.data,
                inx,
                name
            }));
        }
    }
}
export const actionMultipleUpload = (data, inx, itemInx) => {
    return async (dispatch, getState) => {
        const response = await fileService.uploadItemDoc(data, inx, itemInx);
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            let fileDetails = {
                ...response.data.file,
                size: formatFileSize(response.data.file.file_size),
                base64: response.data.base64,
                isNew: true
            };
            dispatch(setItemSupportingDocumentData({ data: { ...fileDetails }, inx, itemInx }));
        }
    }
}

export const actionFileById = (id) => {
    return async (dispatch, getState) => {
        const response = await fileService.getById(id);
        if (response?.status === HTTP_STATUS_CODE.OK) {
            dispatch(setFilePreview(response.data));
            dispatch(setFilePreviewModel(true));
        }
    }
}


export const actionUpdateItemStatus = (data,orderId,navigate) => {
    return async (dispatch, getState) => {
        const response = await tradeService.updateItemStatus(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            await dispatch(actionGetById(orderId));
            await dispatch(setCurrentItem(null));
            await dispatch(setShowComment(false));
            await dispatch(setComment(""));
            navigate(-1); 
        }
    }
}
export const actionDisbursal = (orderSlug) => {
    return async (dispatch, getState) => {
        const response = await paymentService.dispursal(orderSlug);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === HTTP_STATUS_CODE.OK) {
            
        }
    }
}