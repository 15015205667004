// Import files and libraries
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';


//others
import { IconBack, IconEdit, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData } from '../../../store/agent/reducer';
import { setDisabledButton, setSupportingDocumentPlaceholder, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionCreate, actionUpdate } from '../../../store/agent/action'


// custom component
import FilePreview from '../../../component/file-preview';
import { formatFileSize, getFileExtension } from '../../../helper/common-helper';
import { ATTACHMENT_TYPE, DEFAULT_USER_ROLE, ENVIRONMENT, PERMISSION_KEY, PERMISSION_PAGE_KEY, REGEXP, validatePermission } from '../../../config/constant';
import { getDefaultDateFormatOnly } from '../../../helper/date-helper';

import MultipleFilePreview from '../../../component/multiple-file-upload';
import PermissionList from '../../../component/permission-list';
import FormStatus from '../../../component/form-status';
import { actionFileUpload } from '../../../store/general/action';
import { actionGetOrganizationList, actionGetOrgLocationList } from '../../../store/organization/action';
import { actionGetLocationList } from '../../../store/location/action';

const AgentFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState({});
    const [editForm, setEditForm] = useState(false);

    const { formData, updateId, formModel } = useSelector((state) => state.agent);
    const { disabledButton, profile, supportingDocuments } = useSelector((state) => state.general);
    const { organizationList, orgLocationList } = useSelector((state) => state.organization);

    const { formDefaultPermission, defaultUserPermission, userData } = useSelector((state) => state.auth);

    useEffect(() => {
        setErrors({});
        setEditForm(updateId ? false : true);
        setDefaultOrg();
        dispatch(actionGetOrganizationList());

    }, [formModel]);

    const setDefaultOrg = () => {
        if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION) {
            let data = {
                ...formData,
                organization_id: userData.id,
            };

            dispatch(setFormData(data));
        }
    }


    // form validation schema
    let schema = Yup.object().shape({
        first_name: Yup.string()
            .max(50, 'First name must be at most 50 characters long')
            .required('First name is required'),
        last_name: Yup.string()
            .max(50, 'Last name must be at most 50 characters long')
            .required('Last name is required'),
        middle_name: Yup.string()
            .max(50, 'Middle name must be at most 50 characters long')
            .required('Middle name is required'),
        email: Yup.string()
            .max(255, 'Email must be at most 255 characters long')
            .matches(REGEXP.emailRegExp, 'Invalid email address')
            .required('Email is required'),
        phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(20, 'Phone number must be at most 20 characters long')
            .required('Phone number is required'),
        national_id: Yup.string()
            .matches(REGEXP.numberRegExp, 'National ID is not valid')
            .max(20, 'National ID must be at most 20 characters long')
            .required('National ID is required'),
        designation: Yup.string()
            .max(50, 'Designation must be at most 50 characters long')
            .required('Designation is required'),
        organization_id: Yup.string()
            .required('Organization is required'),
        location_id: Yup.string()
            .required('Location is required'),
        gem_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Gem Target is not valid')
            .max(20, 'Monthly Gem Target must be at most 20 characters long')
            .required('Monthly Gem Target is required'),
        metal_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Metal Target is not valid')
            .max(20, 'Monthly Metal Target must be at most 20 characters long')
            .required('Monthly Metal Target is required'),
        work_experience: Yup.string()
            .matches(REGEXP.numberRegExp, 'Work experience is not valid')
            .max(20, 'Work experience must be at most 50 characters long')
            .required('Work experience is required'),

    });

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            let supporting_documents = [];
            if (Object.values(supportingDocuments).length > 0) {
                Object.values(supportingDocuments).map(doc => {
                    if (doc?.isNew) {
                        supporting_documents.push(doc.id);
                    }
                });
            }

            const finalData = {
                first_name: formData.first_name,
                middle_name: formData.middle_name,
                last_name: formData.last_name,
                national_id: formData.national_id,
                email: formData.email,
                phone_number: formData.phone_number,
                work_experience: formData.work_experience,
                designation: formData.designation,
                organization_id: formData.organization_id,
                location_id: formData.location_id,
                gem_target: formData.gem_target,
                metal_target: formData.metal_target,
                profile: profile !== null ? profile.file.id : null,
                active: formData.active,
                supporting_documents,
                permissions: Object.values(formDefaultPermission).filter(permission => permission !== 0)
            };

            if (updateId) { //check the form action update or create
                // update data with update id
                dispatch(actionUpdate(finalData, updateId));
            } else {
                // create form data
                dispatch(actionCreate(finalData));
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        let data;

        if (name === 'date_of_formation') {
            value = getDefaultDateFormatOnly(value);
        }
        if (name === 'organization_id') {
            dispatch(actionGetOrgLocationList(value));
        }

        data = {
            ...formData,
            [name]: value,
        };

        dispatch(setFormData(data));
        validateField(name, value);
    };

    const uploadFile = (e) => {
        const { name } = e.target;
        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        if (name === 'profile') {
            const formData = new FormData();
            formData.append('file', checkFile);
            dispatch(actionFileUpload(formData));
        } else {
            Array.from(e.target.files).forEach((file) => {
                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                let ext = getFileExtension(file.name);
                dispatch(setSupportingDocumentPlaceholder({
                    inx, file: {
                        name: file.name,
                        type: file.type,
                        ext,
                        size: formatFileSize(file.size),
                        isNew: true
                    }
                }));
                const formSDData = new FormData();
                formSDData.append('file', file); // Append the file to the FormData object

                dispatch(actionFileUpload(formSDData, inx, name));
            });
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-10 mb-3">
                        <div className='d-flex align-items-center'>
                            <IconBack
                                width={32}
                                height={32}
                                onIconClick={closeModal}
                                customClass='cursor-pointer'
                            />
                            <h3 className='flex-shrink-1 ms-2 mb-0'>{updateId ? 'Update Agent' : 'Create New Agent'}</h3>
                        </div>
                    </div>
                    {updateId && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.AGENT, PERMISSION_KEY.UPDATE) && (
                        <div className="col-md-2 mb-3 text-end">
                            <button
                                className="btn  btn-purple-primary me-3"
                                type='button'
                                onClick={e => setEditForm(true)}
                            >
                                <IconEdit customClass='text-white' /> Edit
                            </button>
                        </div>
                    )}
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <div className='row'>
                            <div className='col-md-1'>
                                {profile ? (
                                    <div className='placeholder-image-bg'>
                                        <img src={`data:${profile?.type};base64,${profile?.base64}`} />
                                    </div>
                                ) : (
                                    <div className='placeholder-image-bg'>
                                        <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                    </div>
                                )}
                            </div>
                            {editForm && (
                                <div className='col-md-2 align-self-center'>
                                    <div className="upload-btn-wrapper">
                                        <button className="upload-btn btn">Upload a file</button>
                                        <input type="file" name="profile" onChange={uploadFile} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen className='custom-accordiant'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Agent information</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>First Name</label>
                                                <input type="text" className="form-control "
                                                    name="first_name"
                                                    disabled={!editForm}
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.first_name && <span className="error-message">{errors.first_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Middle Name</label>
                                                <input type="text" className="form-control "
                                                    name="middle_name"
                                                    disabled={!editForm}
                                                    value={formData.middle_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.middle_name && <span className="error-message">{errors.middle_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control "
                                                    name="last_name"
                                                    disabled={!editForm}
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.last_name && <span className="error-message">{errors.last_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>National Identity Number</label>
                                                <input type="text" className="form-control "
                                                    name="national_id"
                                                    disabled={!editForm}
                                                    value={formData.national_id}
                                                    onChange={handleChange}
                                                />
                                                {errors.national_id && <span className="error-message">{errors.national_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>E-mail Adddress</label>
                                                <input type="text" className="form-control "
                                                    name="email"
                                                    disabled={!editForm}
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <span className="error-message">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control "
                                                    name="phone_number"
                                                    disabled={!editForm}
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                />
                                                {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Work Experience</label>
                                                <input type="text" className="form-control "
                                                    name="work_experience"
                                                    disabled={!editForm}
                                                    value={formData.work_experience}
                                                    onChange={handleChange}
                                                />
                                                {errors.work_experience && <span className="error-message">{errors.work_experience}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Designation</label>
                                                <input type="text" className="form-control "
                                                    name="designation"
                                                    disabled={!editForm}
                                                    value={formData.designation}
                                                    onChange={handleChange}
                                                />
                                                {errors.designation && <span className="error-message">{errors.designation}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Organization</label>
                                                {
                                                    parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION ? (
                                                        <Dropdown
                                                            disabled={true}
                                                            value={formData.organization_id}
                                                            // onChange={handleChange}
                                                            options={organizationList}
                                                            optionLabel="organization_name"
                                                            optionValue="id"
                                                            placeholder="Select a organization"
                                                            className="w-100 custom-single-dropdown"
                                                            name='organization_id'
                                                        />
                                                    ) : (
                                                        <Dropdown
                                                            disabled={!editForm}
                                                            value={formData.organization_id}
                                                            onChange={handleChange}
                                                            options={organizationList}
                                                            optionLabel="organization_name"
                                                            optionValue="id"
                                                            placeholder="Select a organization"
                                                            className="w-100 custom-single-dropdown"
                                                            name='organization_id'
                                                        />
                                                    )
                                                }


                                                {errors.organization_id && <span className="error-message">{errors.organization_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Location</label>
                                                <Dropdown
                                                    disabled={!editForm}
                                                    value={formData.location_id}
                                                    onChange={handleChange}
                                                    options={orgLocationList}
                                                    optionLabel="location_name"
                                                    optionValue="id"
                                                    placeholder="Select a location"
                                                    className="w-100 custom-single-dropdown"
                                                    name='location_id'
                                                />
                                                {errors.location_id && <span className="error-message">{errors.location_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Gem Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="gem_target"
                                                        disabled={!editForm}
                                                        value={formData.gem_target}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input-group-text" id="gem_targe">Carat</span>
                                                </div>

                                                {errors.gem_target && <span className="error-message">{errors.gem_target}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Metal Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="metal_target"
                                                        disabled={!editForm}
                                                        value={formData.metal_target}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input-group-text" id="metal_targe">GM</span>
                                                </div>

                                                {errors.metal_target && <span className="error-message">{errors.metal_target}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label className='w-100'>Supporting documents</label>
                                                {editForm && (
                                                    <div className="upload-btn-wrapper ">
                                                        <button className="upload-btn-bdr btn ">Upload a file</button>
                                                        <input type="file" name={ATTACHMENT_TYPE.AGENT_SUPPORT_DOCUMENT} multiple onChange={uploadFile} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <MultipleFilePreview files={supportingDocuments} />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Permission</Accordion.Header>
                                <Accordion.Body>
                                    {parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.SUPER_ADMIN ? (
                                        <PermissionList editForm={editForm} />
                                    ) : (
                                        <PermissionList editForm={false} />
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='col-12 mb-3'>
                        <FormStatus
                            onStatusClick={handleChange}
                            active={formData.active}
                            id={updateId}
                            editForm={editForm}
                        />
                    </div>
                    <Divider />
                    <div className="col-12 text-end">
                        <div className="form-group mb-3">
                            {editForm && (
                                <>
                                    <button
                                        className="btn  btn-purple-primary-outline me-3"
                                        type='button'
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={disabledButton}
                                        className="btn  btn-purple-primary"
                                        type='submit'
                                    >
                                        {disabledButton ? <Spinner /> : null} {updateId ? 'Update' : 'Create'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            <FilePreview />
        </>
    );
};


export default AgentFormModel;
