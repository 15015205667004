import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetFileById } from '../store/general/action';
import { ProgressBar } from 'primereact/progressbar';
import { truncateFileName } from '../helper/common-helper';


const MultipleFilePreview = ({files}) => {
  // create a dispatch
  const dispatch = useDispatch();
    
    const viewFile = (attachmentId) => {
        dispatch(actionGetFileById(attachmentId, 'sd'))
    };
    return (
        <>
        {
            Object.values(files).length > 0 &&
            Object.values(files).map((file, index) => (
                <div className="col-md-12" key={index}>                                                    
                    <div className="form-group mb-3 cursor-pointer" onClick={e => viewFile(file?.id)}>
                        <div className="d-flex align-content-center">
                            <div className='flex-shrink-1 align-self-center me-2 position-relative'>
                                <div className='upload-thumb-icon rounded'></div>
                                <div className='img-layer position-absolute top-0 start-0 w-100 h-100 text-center'>
                                    <div className='position-absolute top-50 start-50 translate-middle file-ext-name'>
                                        {file?.placeholder?file.file.ext:(file.ext).toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            <div className='w-100'>
                                <div className="d-flex ">
                                    <div className='me-2'>
                                        {file?.placeholder && <p className={`txt-dec m-0 li-hi-20`}>{truncateFileName(file.file.name)}</p>}
                                        {file?.id && <p className='txt-dec m-0 li-hi-36'>{truncateFileName(file.original_name)}</p>}
                                        {file?.placeholder && <ProgressBar value={file.uploadProgress}></ProgressBar>}
                                    </div>
                                    <div className='flex-shrink-1 align-self-center' >
                                        <p className='m-0'>
                                            {file?.placeholder && <span>{file.file.size}</span>}
                                            {file?.id && <span>{file.size}</span>}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
        </>
    );
};

export default MultipleFilePreview;
